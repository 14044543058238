import { Component, OnInit, ViewChild } from '@angular/core';
import { PurchaseService } from '../purchase.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { IndentDialogComponent } from './indent-dialog/indent-dialog.component';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { AdminService } from '../../admin/admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { RatehelpComponent } from '../../shared/ratehelp/ratehelp.component';

@Component({
  selector: 'app-indent-list',
  templateUrl: './indent-list.component.html',
  styleUrls: ['./indent-list.component.css']
})
export class IndentListComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  DataForm: FormGroup;
  Noti_Array: any = [];
  no_of_msg: number = 0;
  filterIndent: number;
  IndDept: any = [];
  Indenters: any = [];
  Slip_no: number;
  isClicked = false;
  Username: string;
  UserRights: any = [];
  IndRights: string;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable, { static: false }) Approved!: MatTable<any>;

  constructor(private PurService: PurchaseService,
    private HardFunc: HardFuncService,
    private MatSnackBar: MatSnackBar,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private Adminservice: AdminService,
    private notificationSer: NotificationService,
    private commonservice: CommonService) { }

  ngOnInit() {
    this.loading = true;
    this.Username = this.HardFunc.UserName.toLowerCase();
      
    this.DataForm = this.createFormGroup();
    this.getData();
    this.getNotification();
    this.GetIndDepartment();
    this.GetIndeter();
    this.UserRights = ['alpesh','hardik','hms','ams','ashok'];
    this.UserRights.forEach(e => {      
      if(this.Username === e){          
        this.IndRights = e;          
      }
    });
    //this.OpenIndent();
    
  }
  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Slip_no', 'Srno', 'Indt_nm', 'Tdate', 'Firm', 'Product_id', 'Short_nm', 'Qnty1', 'Unit1', 'Pqnty1', 'Remark', 'Aqnty', 'Cmd','Action'];
  }
  getData() {
    this.loading = true;
    this.datalist = [];
    let data: any = {};
    let cnd = " ";
    if(this.DataForm.value.Option === "I"){
      cnd += " and (Tag is null OR Tag = '' '') ";
    }
    if(this.DataForm.value.Option === 'T')
    {
      cnd += " and (Tag = ''A'' AND Po_no is null )";
    }
    if(this.DataForm.value.Status != "*All*"){
      cnd += " and Unit2 = ''"+this.DataForm.value.Status+"'' ";
    }
    if(this.DataForm.value.INDT_NM != "*All*"){
      cnd += " and Indt_nm = ''"+ this.DataForm.value.INDT_NM.trim() +"'' ";
    }      
    data.Cond = cnd;
    data.isUrgent = this.DataForm.get("isUrgent").value
    console.log(data);
    this.PurService.getIndentList(data)
      .subscribe(
        (data: any) => {                    
          this.datalist = data;            
          // this.datalist.map(d => {
          //   d.Remarks = d.Remark + d.Remark1;
          // });          
          this.dataSource = new MatTableDataSource(<any>this.datalist);
          this.DisplayCol();
          this.getNotification();
          this.dataSource.paginator = this.paginator;
          this.loading = false;
        },
        err => {
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          }
        }
      )
  }
  CloseDropDown(data) {
    data.isClicked = false;
    return false;
  }
  IndDelete(Indent_id){

    console.log(Indent_id)
    this.PurService.IndDelete(Indent_id)
      .subscribe((data: any) => {
        console.log(data);
        // this.getData();
        this.notificationSer.warn("Indent Deleted Successfully... ");
      },err => {
        if (err.error.error_description) {
          this.openSnackBar(err.error.error_description, "dismiss");
        }
        else {
          this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
        }
      });
  }
  GetIndeter() {
    this.loading = true;
    this.Indenters = [];
    
    this.PurService.getIndenterList()
      .subscribe(
        (data: any) => {          
           this.Indenters = data;
          // this.loading = false;
        },
        err => {
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          }
        }
      )
  }
  getNotification() {
    this.Noti_Array = [];
    this.Adminservice.getNotificationbyType('IN')
      .subscribe(
        (data: any) => {          
          this.Noti_Array = data;
          this.no_of_msg = 0;
          this.Noti_Array.map(m => {
            this.no_of_msg += 1;
          });
        },
        err => {
          let errstr = this.HardFunc.getError(err);
          console.log(err);
          this.notificationSer.warn(errstr);
        }
      )
  }
  Ratehelp(Product_id:number,Short_nm: string){
    console.log(Product_id);
    const dialogRef = this.dialog.open(RatehelpComponent, {
      width: '1100px',
      height: '510px',
      data: { Product_id: Product_id, Short_nm: Short_nm  }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
    
  }
  //element.Indent_id,element.Firm,element.Slip_no,element.Short_nm,element.Indt_nm,element.Indt_id
  IndentEdit(data){
    console.log(data.Remark1);
    console.log(data.Remark);
    const dialogRef = this.dialog.open(IndentDialogComponent, {
      width: '1000px',
      height: '500px',
      data: { Ordno: data.Indent_id, Oloc: 1, Slip_no: data.Slip_no, Short_nm: data.Short_nm, Indt_nm: data.Indt_nm, 
              Indt_id: data.Indt_id, Remark1: data.Remark1, Purpose1: data.Purpose1, Qnty1: data.Qnty1, Srno: data.Srno, Aqnty: data.Aqnty  }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  GetIndDepartment() {
    this.loading = true;
    this.commonservice.CodeList('Ind_Dept')
      .subscribe(
        (data: any) => {          
          this.IndDept = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.HardFunc.getError(err);
          this.notificationSer.warn(errstr);
        }
      );
  }

  OpenInvoice(Invno: string){
    this.applyFilter(Invno.toString());
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  Delete_Noti(Ordno: number): void{
    this.loading = true;
    this.Adminservice.Delete_notification(Ordno)
    .subscribe(
      (data:any)=>{
      this.loading = false;
      this.getNotification();
      },
      err => {
      this.loading = false;
      let errstr =  this.HardFunc.getError(err);
      console.log(err);
      }
    )
}
  IndApprove(Indent_id: number,Qnty1: number, Aqnty: number,Tag: string){
    console.log(Tag);
    this.PurService.IndApprove(Indent_id, Qnty1, Aqnty,Tag)
      .subscribe(
        (data: any) => {
          this.Approved.renderRows();
          this.loading = false;
          this.notificationSer.success("Indent Approved...");
        },
      )
  }
  onRightClick(data) {
    console.log(data);
    data.isClicked = true;
    return false;
  }
  createFormGroup() {
    return this.fb.group({
      Option: new FormControl('I'),
      Status: new FormControl('*All*'),
      INDT_NM: new FormControl('*All*'),
      isUrgent: new FormControl(false)
    });
  }
 
  openSnackBar(message: string, action: string) {
    this.MatSnackBar.open(message, action, {
      duration: 2000,
    });
  }
}
