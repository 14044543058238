import { Component, OnInit } from '@angular/core';
import { PurchaseService } from '../../purchase.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';
import { PoGenComponent } from '../po-gen.component';
import { MatDialog } from '@angular/material';
import { PdfListComponent } from '../pdf-list/pdf-list.component';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-po-list',
  templateUrl: './po-list.component.html',
  styleUrls: ['./po-list.component.css']
})
export class PoListComponent implements OnInit {

  loading: boolean = false;
  PoList: any = [];
  constructor(private PurchaseService: PurchaseService,
              private hardfunc: HardFuncService,
              private _http: HttpClient,
              private dialog: MatDialog,
              private NotificationService: NotificationService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.GetDatas();
  }
  GetDatas(){
    this.loading = true;
    this.PurchaseService.GetPOList()
      .subscribe((data: any) => {        
        this.loading = false;
        this.PoList = data;
        // this.PoList.map(p => {
        //   p.Podt = p.Podt.ToString("dd/MM/yyyy");
        // });        
      });
  }
  DownloadPDF(data: any){            
      let pono = data.Pono;
      let Party_name = data.Party_name;
      let Country = data.Country_nm;
      let headers = this.hardfunc.PostHeaders();   

      let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/CreatePoPDF?Pk_id="+ data.Pk_id + "&Revno=" + data.Revno
      this._http.post(url, null, {headers: headers, responseType: 'blob' as 'json'})
        .subscribe((data1: any) => {              
            const data = window.URL.createObjectURL(data1);

            var link = document.createElement('a');
            link.href = data;
            link.target = "_blank";
            var CurrDate = new Date();
            
            link.download = "PO"+pono+"-"+Party_name+"-"+Country+".pdf";
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);               
        });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.PoList.filter = filterValue.trim().toLowerCase();
  }
  PDFListDialog(data: any){
    const dialogRef = this.dialog.open(PdfListComponent, {
      width: '200px',      
      data: { data: data }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  DeletePo(Pk_id: number){
    //, Revno: number
    // this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
		// .afterClosed().subscribe(res =>{
		//   if(res){
			this.loading = true;
			const dialogRef = this.PurchaseService.DeletePO(Pk_id)
			.subscribe(
			  (data:any)=>{
				this.loading = false;
				this.NotificationService.warn("Data deleted successfully");				
			  },
			  err => {
				this.loading = false;
				let errstr =  this.hardfunc.getError(err);
				console.log(err);
				this.NotificationService.warn(errstr);
			  }
			)
			
			// this.notificationService('Deleted Successfully');
		//   }
		// });
  }
  POView(data: any){
    console.log(data);
    
  }
  PO_Edit(data: any, Status: string){
    const dialogRef = this.dialog.open(PoGenComponent, {
      // width: '1300px',
      height: '700px',
      data: { id: data.Pk_id, Status: Status }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
