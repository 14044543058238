import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HardFuncService } from './hardfunc.service';

@Injectable()
export class CommonService {
    getheaders;
    postheaders;

    constructor(private http: HttpClient,
        private hardfunc: HardFuncService
    ) {

        this.getheaders = new HttpHeaders({
            'Authorization': 'Bearer ' + this.hardfunc.UserToken
        })
        this.postheaders = new HttpHeaders({
            'Authorization': 'Bearer ' + this.hardfunc.UserToken
        })
    }



    CodeList(codenm) {
        let url = this.hardfunc.BaseAPIUrl + "api/Common/CodemastsAPI/GetCodeListbyCodenm?codenm=" + codenm
        return Observable.create((observer) => {
            return this.http.get(url)
                .subscribe(data => {
                    observer.next(data);
                },
                    err => {
                        console.log(err);
                    });
        });
    }
  


    ProductList() {
        let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetAllProduct?cond=" + null
        return Observable.create((observer) => {
            return this.http.get(url)
                .subscribe(data => {
                    observer.next(data);
                },
                    err => {
                        console.log(err);
                    });
        });
    }
    PrdGroupListAll() {
        let url = this.hardfunc.BaseAPIUrl + "api/PrdGroupsAPI/GetPrdGroupAll"
        return this.http.get(url, { headers: this.getheaders });
    }
    PrdGroupList() {
        let url = this.hardfunc.BaseAPIUrl + "api/PrdGroupsAPI/GetPrdGroupAll"
        return this.http.get(url, { headers: this.getheaders });
    }
   
    AcLedger(data) {
        let url = this.hardfunc.BaseAPIUrl + "api/AcLedgerAPI/Acledger"

        var headers = new HttpHeaders({
            'Accept': 'application/pdf',
            'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
        data.Firmx = this.hardfunc.Firmx;
        data.Yrx = this.hardfunc.Yrx;
        data.Fdates = this.hardfunc.DTOC(data.Fdate);
        data.Tdates = this.hardfunc.DTOC(data.Tdate);

        if (data.Output == "PDF") {
            return Observable.create((observer) => {
                return this.http.post(url, data, { headers: headers, responseType: 'arraybuffer' })
                    .subscribe(data => {
                        observer.next(data);
                    },
                    err => {
                        console.log(err);
                        observer.error(err);
                        
                    });
            });

        }
        else {
            return this.http.post(url, data, { headers: headers });
        }


    }

    OpBal(data) {
        let url = this.hardfunc.BaseAPIUrl + "api/AcLedgerAPI/OpBal"

        var headers = new HttpHeaders({
            'content-Type': 'application/json',
            'process-Data': 'false',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
        data.Firmx = this.hardfunc.Firmx;
        data.Yrx = this.hardfunc.Yrx;

        return this.http.post(url, data, { headers: headers });
    }

    GetPartyDetail(pmst_cod: string, Firmx:string = this.hardfunc.Firmx , Yrx:string = this.hardfunc.Yrx) {
        let url = this.hardfunc.BaseAPIUrl + "api/FinanceAPI/GetPartyDetail"
        var data: any = {}
        var headers = new HttpHeaders({
            'content-Type': 'application/json',
            'process-Data': 'false',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
        data.pmst_cod = pmst_cod;
        data.Firmx = Firmx;
        data.Yrx = Yrx;
      
        return this.http.post(url, data, { headers: headers });
    } 

    GetImpParty(pmst_cod: string, Firmx:string = this.hardfunc.Firmx , Yrx:string = this.hardfunc.Yrx) {
        let url = this.hardfunc.BaseAPIUrl + "api/Sales/partysAPI/ImpPartyDetail?pmst_cod=" +pmst_cod
        var data: any = {}
        var headers = new HttpHeaders({
            'content-Type': 'application/json',
            'process-Data': 'false',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
        data.pmst_cod = pmst_cod;
        data.Firmx = Firmx;
        data.Yrx = Yrx;
      
        return this.http.post(url, data, { headers: headers });
    } 
  
    GetPartyList(f:string,y:string){
        let url = this.hardfunc.BaseAPIUrl+"api/FinanceAPI/GetPartyList"
        var data:any= {};
        data.Firmx = f;
        data.Yrx = y;
        return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    
    getAllUsers(){
        let url = this.hardfunc.BaseAPIUrl + "api/UsersAPI/GetAllUsers";
        return this.http.get(url,{headers:this.hardfunc.GetHeaders()});
      }
    GetTrialBal() {

        let url = this.hardfunc.BaseAPIUrl + "api/FinanceAPI/GetPartyOs"
        var data: any = {}
        var headers = new HttpHeaders({
            'content-Type': 'application/json',
            'process-Data': 'false',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
        data.Firmx = this.hardfunc.Firmx;
        data.Yrx = this.hardfunc.Yrx;

        return this.http.post(url, data, { headers: headers });

    }

    GetStates() {

        let url = this.hardfunc.BaseAPIUrl + "api/CommonAPI/GetStates"
        var data: any = {}
        var headers = new HttpHeaders({
            'content-Type': 'application/json',
            'process-Data': 'false',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
        data.Firmx = this.hardfunc.Firmx;
        data.Yrx = this.hardfunc.Yrx;

        return this.http.post(url, data, { headers: headers });
    }

}