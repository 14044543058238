import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { MatInputModule, MatFormFieldModule, MatIconModule, MatCardModule, MatTabsModule, MatMenuModule, MatPaginatorModule, MatTableModule, MatProgressBarModule, MatButtonModule, MatChipsModule, MatListModule, MatGridListModule, MatDialogModule, MatToolbarModule, MatSelectModule, MatSidenavModule, MatTooltipModule, MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule, MatCheckboxModule, MatExpansionModule, MatRadioButton, MatRadioModule, MatSlideToggleModule } from '@angular/material';
import { ManufacturingRoutes } from './manufacturing.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BOMComponent } from './bom/bom.component';
import { GrnListComponent } from './grn-list/grn-list.component';
import { Grn1ListComponent } from './grn1-list/grn1-list.component';
import { StkRepoComponent } from './stk-repo/stk-repo.component';
import { OpenstockComponent } from './stk-repo/openstock/openstock.component';
import { GatepassImportComponent } from './gatepass-import/gatepass-import.component';
import { PrdHistoryComponent } from './stk-repo/prd-history/prd-history.component';
import { PrdctnComponent } from './prdctn/prdctn.component';
import { PrdctnCrudComponent } from './prdctn-crud/prdctn-crud.component';
import { PrdctnListComponent } from './prdctn/prdctn-list/prdctn-list.component';
import { StkrepallComponent } from './stk-repo/stkrepall/stkrepall.component';
import { DashboardInventoryComponent } from './dashboard-inventory/dashboard-inventory.component';
import { BomstockComponent } from './bomstock/bomstock.component';
import { BomUtibyComponent } from './stk-repo/bom-utiby/bom-utiby.component';
import { JobworkDetailComponent } from './stk-repo/jobwork-detail/jobwork-detail.component';
import { FixStockComponent } from './fix-stock/fix-stock.component';
import { Stkrepall1Component } from './stk-repo/stkrepall1/stkrepall1.component';
import { PparaCrudComponent } from './ppara-crud/ppara-crud.component';
import { PparaCreateComponent } from './ppara-create/ppara-create.component';
import { PrdgroupCrudComponent } from './prdgroup-crud/prdgroup-crud.component';
import { PrdgroupCreateComponent } from './prdgroup-crud/prdgroup-create/prdgroup-create.component';
import { SparaDialogComponent } from './prdgroup-crud/spara-dialog/spara-dialog.component';
import { PrdgrpBySparaComponent } from './prdgroup-crud/prdgrp-by-spara/prdgrp-by-spara.component';
import { PmatrixpivotComponent } from './stk-repo/pmatrixpivot/pmatrixpivot.component';
import { KeysPipe } from './stk-repo/keys.pipe';
import { ProductMasterComponent } from './fix-stock/product-master/product-master.component';
import { ReportSampleComponent } from './report-sample/report-sample.component';
import { ProductSalesComponent } from './product-sales/product-sales.component';
import { CommonPipesModule } from 'src/app/Pipes/common/common-pipes.module';
import { GeneralModule } from '../general/general.module';
import { FromToHelpComponent } from './prdgroup-crud/from-to-help/from-to-help.component';
import { MinstockDashComponent } from './minstock-dash/minstock-dash.component';
import { IndOrderListComponent } from './ind-order-list/ind-order-list.component';
import { JobworkRepoComponent } from './jobwork-repo/jobwork-repo.component';
import { BomSequenceComponent } from './stk-repo/bom-sequence/bom-sequence.component';

const Material = [
  MatInputModule,
  MatIconModule,
  MatCardModule,
  MatMenuModule,
  MatProgressBarModule,
  MatButtonModule,
  MatChipsModule,
  MatListModule,
  MatSidenavModule,
  MatGridListModule,
  MatTooltipModule,
  MatToolbarModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatDialogModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatTabsModule
]

@NgModule({
  declarations: [ BOMComponent, GrnListComponent, Grn1ListComponent, StkRepoComponent, OpenstockComponent, GatepassImportComponent, PrdHistoryComponent, PrdctnComponent, PrdctnCrudComponent, PrdctnListComponent, StkrepallComponent, DashboardInventoryComponent, BomstockComponent, BomUtibyComponent, JobworkDetailComponent, FixStockComponent, Stkrepall1Component, PparaCrudComponent, PparaCreateComponent, PrdgroupCrudComponent, PrdgroupCreateComponent, SparaDialogComponent, PrdgrpBySparaComponent, PmatrixpivotComponent, KeysPipe, ProductMasterComponent, ReportSampleComponent, ProductSalesComponent, FromToHelpComponent, MinstockDashComponent, IndOrderListComponent, JobworkRepoComponent, BomSequenceComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    Material,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    GeneralModule,

    RouterModule.forChild(ManufacturingRoutes)
  ],
  entryComponents: [
    BOMComponent,
    OpenstockComponent,
    PrdHistoryComponent,
    PrdctnCrudComponent,
    BomstockComponent,
    BomUtibyComponent,
    JobworkDetailComponent,
    PparaCreateComponent,
    PrdgroupCreateComponent,
    SparaDialogComponent,
    PrdgrpBySparaComponent,
    ProductMasterComponent,
    FromToHelpComponent
  ],
})
export class ManufacturingModule { }
