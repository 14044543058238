import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { PurchaseService } from '../../purchase.service';
import { AdminService } from 'src/app/Views/admin/admin.service';

export interface ModalData {
  id: number
}

@Component({
  selector: 'app-imp-prod-family',
  templateUrl: './imp-prod-family.component.html',
  styleUrls: ['./imp-prod-family.component.css']
})
export class ImpProdFamilyComponent implements OnInit {

  // isClicked = false;
  NewEvent: any = [];
  Message: any = "";
  loading: boolean = false;
  p: number = 0;
  DataForm: FormGroup;
  initialValues: any[];

  constructor(
    private _http: HttpClient,
    private hardfunc: HardFuncService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private NotificationService: NotificationService,
    private PurchaseService: PurchaseService,
    private Adminservice: AdminService) { }

  ngOnInit() {
    this.GetData();
    this.loading = true;
    this.DataForm = this.CreateFormGroup();
    this.initialValues = this.DataForm.value;
    // if (this.ModalData.id== -1){
    //   this.NewEvent = {
    //     SubData:[]
    //   };
    // }
    // else
    // {
    //   let url =  this.hardfunc.BaseAPIUrl+"/api/Common/CodemastsAPI/GetDatabyId?id="+this.ModalData.id;
    //   var headers = new HttpHeaders({ 'content-Type':'false' });
    //   this._http.get(url,{headers:headers})
    //     .subscribe(data1 => {
    //       this.loading=false;
    //       this.NewEvent = JSON.parse(data1.toString());
    //       console.log(this.NewEvent);
    //   },
    //   err=> {
    //     console.log(err);
    //     this.loading=false;
    //     //alert("Error in data getting... !!! ");
    //     this.openSnackBar("Error in data getting... !!!" + err,"dismiss");
    //   });
    // }    
  }
  AddItems() {
    (this.DataForm.controls['SubData'] as FormArray).push(this.CreateSubData());
  }
  DeleteRow(i: number) {
    (this.DataForm.controls['SubData'] as FormArray).removeAt(i);
  }

  Save() {            
    let url = this.hardfunc.BaseAPIUrl + "/api/Common/CodemastsAPI/SaveImpPrdFamily";
    var headers = new HttpHeaders({ 'content-Type': 'application/json' });

    this._http.post(url, JSON.stringify(this.DataForm.value), { headers: headers })
      .subscribe(data => {
        this.loading = false;
        this.NewEvent.SubData.map(d => {
          d.isClicked = false;
        });
        this.NotificationService.success("Data Saved Sucessfully... ");
        this.ResetData()
        this.GetData();
        window.location.reload();
      },
        err => {
          console.log(err);
          this.loading = false;
          // alert("Error in data getting... !!! ");
          this.openSnackBar("Error in data getting... !!!" + err, "dismiss");
        });
  }
  UpdProdFam(Subcode_id: number, Vals: string) {
    this.PurchaseService.UpdProdFam(Subcode_id, Vals)
      .subscribe((data: any) => {
        this.NewEvent.SubData.map(d => {
          d.isClicked = false;
        });
        this.NotificationService.success("Data Updated Sucessfully... ");
      },
        err => {
          console.log(err);
          this.loading = false;
          //alert("Error in data getting... !!! ");
          this.openSnackBar("Error in data getting... !!!" + err, "dismiss");
        });
  }
  GetData() {
    let url = this.hardfunc.BaseAPIUrl + "/api/Common/CodemastsAPI/GetDatabyId?id=" + 37
    var headers = new HttpHeaders({ 'content-Type': 'false' });
    this._http.get(url, { headers: headers })
      .subscribe(data1 => {
        this.loading = false;
        this.NewEvent = JSON.parse(data1.toString());

        this.NewEvent.SubData.map(d => {
          d.isClicked = false;
        });        
      },
        err => {
          console.log(err);
          this.loading = false;
          //alert("Error in data getting... !!! ");
          this.openSnackBar("Error in data getting... !!!" + err, "dismiss");
        });
  }
  DelProdFam(Subcode_id: number) {
    this.Adminservice.openConfirmDialog("Are you sure you want to delete this ?")
      .afterClosed().subscribe(res => {
        if (res) {
          this.loading = true;
          const dialogRef = this.Adminservice.DelProdFam(Subcode_id)
            .subscribe(
              (data: any) => {
                this.loading = false;
                this.NotificationService.warn("Data deleted successfully");
                this.GetData();
                window.location.reload();
              },
              err => {
                this.loading = false;
                let errstr = this.hardfunc.getError(err);
                console.log(err);
                this.NotificationService.warn(errstr);
              }
            )
        }
      });
  }

  ResetData() {
    this.DataForm.reset();
    this.DataForm.patchValue(this.initialValues);
    Object.keys(this.DataForm.controls).forEach(key => {
      this.DataForm.get(key).setErrors(null);
    });
  }
  CreateFormGroup() {
    return this.fb.group({
      // Product_id: new FormControl(),
      // Product_nm: new FormControl(''),
      Codemast_id: new FormControl(37),
      Codenm: new FormControl('ImpPrdGrp'),
      Codedesc: new FormControl('Import Product Group'),
      SubData: this.fb.array([this.CreateSubData()]),
    })
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  CreateSubData(): FormGroup {
    return this.fb.group({
      Codemast_id: new FormControl(37),
      Cd: new FormControl(''),
      Vals: new FormControl(''),
      Codenm: new FormControl(''),
      Subcode_id: new FormControl(-1),
    });
  }
}
