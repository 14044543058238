import { Injectable } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class DmsService {

  postheaders;
  constructor(
    private http: HttpClient,
    private hardfunc: HardFuncService,
    private dialog:MatDialog) {}


  SaveDocu(data:FormData){
    console.log(data);
		let url = this.hardfunc.BaseAPIUrl + "api/DMSAPI/SaveDocu"
    var headers = new HttpHeaders({
      
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
		return this.http.post(url, data, { headers:headers });
	}
  SaveImpoDocu(data:FormData){
    console.log(data);
		let url = this.hardfunc.BaseAPIUrl + "api/DMSAPI/ImpoDocu"
    var headers = new HttpHeaders({
      
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
		return this.http.post(url, data, { headers:headers });
	}
  SaveExternalUrl(){
    let url = this.hardfunc.BaseAPIUrl + "api/DMSAPI/SaveExtUrl"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetDocu(Firmx: string, Ordno: number, Oloc: number, Vtype: string,Wmodule_id: number) {
		let data: any = {};
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		data.Ordno = Ordno;
		data.Oloc = Oloc;
    data.Vtype = Vtype;
    data.Wmodule_id = Wmodule_id;
    // data.Username = this.hardfunc.UserName;
		let url = this.hardfunc.BaseAPIUrl + "api/DMSAPI/GetDatabyOrdno?Ordno="+Ordno+ "&Oloc="+Oloc+"&Vtype="+Vtype+"&Wmodule_id=" +Wmodule_id+ "&Username=" + this.hardfunc.UserName;
		return this.http.get(url,{headers:this.hardfunc.GetHeaders()});
	}	
  Delete_docu(Docu_id: number){
		let url = this.hardfunc.BaseAPIUrl+"api/DMSAPI/DeleteData?id="+Docu_id;
          return this.http.post(url,null,  { headers: this.hardfunc.PostHeaders() });
	}
  getDatas(data:any) {
    data.Firmx = this.hardfunc.Firmx;
    data.Yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl+"api/Sales/EMDsAPI/GetDatas"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
  }
  GetDatabyId(pk_id: number) {
		let data: any = {};
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx;
		data.pk_id = pk_id;
		let url = this.hardfunc.BaseAPIUrl + "api/Sales/EMDsAPI/GetDatabyId?id="+pk_id;
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
	}

  LoadImagebyId(Docu_id : number)
  {
    let data: any = {};
		data.Firmx = this.hardfunc.Firmx;
		data.Yrx = this.hardfunc.Yrx; 
    let url = this.hardfunc.BaseAPIUrl + "api/DMSAPI/LoadImagebyId?Docu_id="+Docu_id;
		return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });

  }

}
