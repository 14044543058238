import { Component, OnInit, Inject } from '@angular/core';
import { AdminService } from '../admin.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { A } from '@angular/cdk/keycodes';
import { CopyrightsComponent } from '../fmodule/copyrights/copyrights.component';

@Component({
  selector: 'app-fusermast',
  templateUrl: './fusermast.component.html',
  styleUrls: ['./fusermast.component.css']
})
export class FusermastComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  Filtertext:string = "";
  perm: any = [];
  UserData: any = [];
  repmodel: any = {};

  constructor(private adminservice: AdminService,
              private hardfunc: HardFuncService,
              private notificationservice: NotificationService,
              @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.getData();
    this.GetUserData();
    this.loading = true;
  }
  // DisplayCol(){
  //   this.displayedColumns = ['']
  // }
  getData() {
    this.loading = true;
    this.datalist = [];
    this.adminservice.GetFModulePermmsionbyUser(this.ReceivedData.Username)
      .subscribe((data: any) => {
        
        this.datalist = JSON.parse(data);
        console.log(this.datalist);
        this.loading = false;
        this.datalist.map(m => {
          m.PermList = [];
          let str = (m.Permission ? m.Permission.substring(0, 6) : 'NNNNNN');
          for (var i = 0; i < str.length; i++) {
            let vals = str.substring(i, i + 1) === 'Y' ? true : false;
            
            let txt = "";
            switch (i) {
              case 0:
                txt = m.chk1
                break;
              case 1:
                txt = m.chk2
                break;
              case 2:
                txt = m.chk3
                break;
              case 3:
                txt = m.chk4
                break;
              case 4:
                txt = m.chk5
                break;
              case 5:
                txt = m.chk6
                break;

            }
            m.PermList.push({ chk: vals, txt: txt });            
          }
        });
      });
  }
  GetUserData(){
    this.loading = true;    
    this.adminservice.getFusermastData()
      .subscribe((data: any) => {
          console.log(data);          
          this.UserData = data;          
          this.loading = false;
        });
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationservice.warn(errstr);
        }
  }
  SaveData(){
    this.loading = true;    
    this.datalist.map(m=> {
      m.Permission = "";
      m.PermList.map(w=>{
          if (w.chk===true)
          {m.Permission=m.Permission+'Y'}
          else
          {m.Permission=m.Permission+'N'}
      });
    });
    this.adminservice.SavePermissionbyFUsermastModule(this.ReceivedData.Username,this.datalist )
      .subscribe(data => {
        this.loading=false;
        this.getData();
        this.notificationservice.success(" Data saved Successfully... ");
      },
      err=> {
        this.loading = false;
        let errstr =  this.hardfunc.getError(err);
        console.log(err);
        this.notificationservice.warn(errstr);
        
      });
  }
  CopyUser(){
    this.adminservice.GetCopyRights(this.ReceivedData.Username,this.repmodel.CopyRights)
      .subscribe((data: any) => {
        this.notificationservice.success("Data Saved Successfully... ");
        console.log(this.datalist);
      });
  }
}
