import { HttpClient } from '@angular/common/http';
import { Component,Inject, Optional, OnInit, Input } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/Common/common.service';
import { PurchaseService } from '../purchase.service';
import { MatDialog } from '@angular/material';
import { PoItemComponent } from './po-item/po-item.component';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PoListComponent } from './po-list/po-list.component';
import * as moment from 'moment'

@Component({
  selector: 'app-po-gen',
  templateUrl: './po-gen.component.html',
  styleUrls: ['./po-gen.component.css'],  
})
export class PoGenComponent implements OnInit {

  loading: boolean = false;
  repmodel: any = {};
  DirectorList: any = [];
  DataForm: FormGroup;
  initialValues : any [];
  ProductList: any = [];
  PartyList: any = [];
  filteredOptions: any = [];
  filteredItems: any = [];
  Documents: any = [];
  Doculist: any = [];
  AllProducts: any = [];
  submitted: boolean;
  title: string;
  CountryData: any = [];
  ProdListofParty: any = [];
  minDate: Date;
  // @Input('Party_id') Party_id:  number;
  PayTerms: any = [];

  constructor(private hardfunc: HardFuncService,
              private _http: HttpClient,
              private fb: FormBuilder,
              private commonservice: CommonService,
              private POService: PurchaseService,
              private dialog: MatDialog,
              private NotificationService: NotificationService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              @Optional() public dialogRef: MatDialogRef<PoListComponent>,
            ) { }

  ngOnInit() {
    this.loading = true;
    // console.log(this.Party_id);    
    this.GetPartyList();
    // this.GetAllProducts();
    this.GetHandles();
    this.GetAllImpPrd();
    this.PO_Documents();
    this.GetCountry();  
    this.GetLastNo();  
    this.PaymentTerms();
    this.DataForm = this.CreateFormGroup();
    this.initialValues = this.DataForm.value;    
    this.GetData();
    this.Party_auto();
    this.OnPartyGen();
    this.repmodel.Prepared = "";
    this.DataForm.get('Party_nm').valueChanges.subscribe(response => {
      this._filter(response);
    });          
    this.minDate= new Date();
    // this.DataForm.get("Podt").patchValue(new Date());
    // this.DataForm.get("Podt").valueChanges.subscribe(res=>{
    //   console.log(res);
    //   this.minDate = new Date(res.PublishedFrom);
    // });
  }
  Party_auto() {    
    let party_arr: FormArray = this.DataForm.controls['SubDatas'] as FormArray;
    for (let Party_control of party_arr.controls) {
      let Party_group: FormGroup = Party_control as FormGroup;
      Party_group.controls['Product_nm'].valueChanges.subscribe(response => {
        this._Prdfilter(response);
      });     
    }
  }
  SelectedItem(){    
    let party_arr: FormArray = this.DataForm.controls['SubDatas'] as FormArray;
    for (let Party_control of party_arr.controls) {
      let Party_group: FormGroup = Party_control as FormGroup;      
      this.AllProducts.map(b => {        
        if(b.nm === Party_group.controls['Product_nm'].value)
        {
          Party_group.controls['Itcode'].patchValue(b.id);
          Party_group.controls['HsnCode'].patchValue(b.HsnCode);
        }
      });       
    }
  }
  SaveData() {
    this.loading = true;
    this.submitted = true;
    if(!this.DataForm.valid) {
      this.NotificationService.warn("Please select currency...");
      this.loading = false;
      return;
    }  
    console.log(this.DataForm.value);
    
    if(this.DataForm.get("Pk_id").value < 0){
      let index = 0;
      let Bsctot: number = 0;
      let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
            
      formarray.value.map(d => {        
        let formgroup = (formarray.at(index) as FormGroup);
        formgroup.get("Srno").patchValue(index);                          
        index++;  
      });   
    }    
    
    this.Doculist = [];
    this.Documents.map(d => {
      
      if(d.check === true){
        this.Doculist.push({          
          Subcode_id: d.Subcode_id,          
          Qnt: d.Qnt,          
        });                
      }
    });
    console.log(this.Doculist);  
    this.DataForm.get("Documents").patchValue(JSON.stringify(this.Doculist));
    console.log(this.PartyList);
    this.PartyList.map(m => {
      if(m.nm === this.DataForm.get("Party_nm").value){
        this.DataForm.get("Party_id").patchValue(m.id);
      }
    });
    console.log(this.AllProducts);
    
    this.DataForm.get("Prepared").patchValue(this.repmodel.Prepared);
    this.DataForm.get("Auth_by").patchValue(this.repmodel.Auth_by);
    console.log(this.DataForm.value);
    this.POService.POSave(this.DataForm.value)
      .subscribe((data: any) => {
        console.log(data);       
        this.NotificationService.success("Data Saved Successfully... ");
        this.ResetData();
        this.GetData();
        this.loading = false;
        window.location.reload();
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        this.NotificationService.warn(errstr);
      });
  }
  OnPartyGen(){    
    if(this.ReceivedData.Status === "G"){
      this.title = "Generate PO"
      this.DataForm.get("Party_nm").patchValue(this.ReceivedData.data.Party_name);    
      this.DataForm.get("Address").patchValue(this.ReceivedData.data.Address);    
      this.DataForm.get("Email1").patchValue(this.ReceivedData.data.Email1);    
      this.DataForm.get("Phone").patchValue(this.ReceivedData.data.Phone);
      this.DataForm.get("Person1").patchValue(this.ReceivedData.data.Person1);    
      // this.DataForm.get("Country_id").patchValue(this.ReceivedData.data.Country_id);    
      this.DataForm.get("Party_id").patchValue(this.ReceivedData.data.Party_id);
      this.DataForm.get("Phone1").patchValue(this.ReceivedData.data.Phone1);
      this.DataForm.get("Email").patchValue(this.ReceivedData.data.Bus_email);
      this.DataForm.get("Phone").patchValue(this.ReceivedData.data.Bus_phone);     
      this._Prdfilter(""); 
    }
  }
  
  GetData() {
    if (this.ReceivedData) {
      
      if (this.ReceivedData.id == -1) {
        this.title = "Generate PO";
      }
      else {
        if (this.ReceivedData.Status === "R" || this.ReceivedData.Status === "E") {
        this.POService.GetDatabyId(this.ReceivedData.id)
          .subscribe(
            (data: any) => {
              console.log(data);              
              if(this.ReceivedData.Status === 'R'){
                data.EditRevise = "R"; 
                this.title = "PO Revise";
              }else{
                data.EditRevise = 'E';
                this.title = "PO Edit";
              }                          
              // this.DataForm.get("Pono").patchValue(data.Pk_id);              
              
              console.log(data.Podt);
              
              this.repmodel.Prepared = data.Edit_by || data.Create_by;
              this.repmodel.Auth_by = data.Auth_by; 
              this.DataForm.get("Party_nm").patchValue(data.Party_name)
              this.DataForm.patchValue(data);

              data.Docu.map(d => {                  
                this.Documents.map(w => {
                    if(d.Subcode_id === w.Subcode_id){                      
                      w.check = true;
                      w.Qnt = d.Qnt;
                    }
                });
              });
              const formarr = new FormArray([]);
              data.SubDatas.map(m => {
                formarr.push(
                  this.fb.group({
                    // Srno: 1,
                    PO_pkid: m.PO_pkid,
                    Pk_id: m.Pk_id,
                    Itcode: m.Itcode,
                    Product_nm: m.Pro_detail,
                    HsnCode: m.HsnCode,
                    Qnty: m.Qnty,
                    Rate: m.Rate,
                    Ittot: m.Ittot,
                    Year: m.Year,
                    Noted: "",
                  })
                );
              });
              this.DataForm.setControl("SubDatas", formarr);              
              this.DataForm.get("Podt").patchValue(this.formatDate(new Date(data.Podt)));
              return false;
            },
            err => {
              let errstr = this.hardfunc.getError(err);
              this.NotificationService.warn(errstr);
            }
          )
        }
      }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.id = -1;
      this.title = "Generate PO";
    }
  }  
  GetAllImpPrd(){
    this.AllProducts = [];
    this.POService.GetAllImpPrd()
      .subscribe((data: any) => {        
        this.AllProducts = data;        
        this.AllProducts = this.AllProducts.map((x) => {
          return {
            nm: x.Product_nm.trim(), //+'-'+ x.Party_city.trim() + '-'+ x.Party_Stat.trim()
            id: x.Product_id,
            HsnCode: x.HsnCode
          }
        });        
      });
  }
  ProductListOfParty(){    
    this.PartyList.map(d => {
      if(d.nm === this.DataForm.get("Party_nm").value){        
        this.POService.ProductListbyParty(d.id)
          .subscribe((data: any) => {
            console.log(data);
            this.ProdListofParty = data;            
            this.ProdListofParty = this.ProdListofParty.map((x) => {
              return {
                nm: x.Product_nm.trim(),
                id: x.Product_id
              }
            });   
            this._Prdfilter("");
            // const formarr = new FormArray([]);
            //   data.map(m => {
            //     formarr.push(
            //       this.fb.group({
            //         Pk_id: 0,
            //         Srno: 0,
            //         Itcode: m.Product_id,
            //         Prod_nm: m.Product_nm,                    
            //         HsnCode: m.HsnCode,
            //         Qnty: 0,
            //         Rate: 0,
            //         Bsc: 0,
            //         Ittot: 0,
            //         Year: this.hardfunc.Yrx
            //       })
            //     );
            //   });
            //   this.DataForm.setControl("SubDatas", formarr);
          });
      }
    });
  }
  ImPoDetailsbyPk_id(){
    this.PartyList.map(d => {
      if(d.nm === this.DataForm.get("Party_nm").value){        
        this.POService.ImPoDetailsbyPk_id(d.id)
          .subscribe((data: any) => {
            console.log(data);
            // const formarr = new FormArray([]);
            //   data.map(m => {
            //     formarr.push(
            //       this.fb.group({
            //         ItCode: 0,
            //         ItemDescr: m.Product_nm,                    
            //         Hsn_code: "",
            //         Qnty: 0,
            //         Rate: 0,
            //         Ittot: 0
            //       })
            //     );
            //   });
            //   this.DataForm.setControl("SubDatas", formarr);
          });
      }
    });
  }
  GetPartyDetails() {
    this.PartyList.map(d => {
      if(d.nm === this.DataForm.get("Party_nm").value){                
          this.POService.GetDataParty(d.id)
            .subscribe(
              (data: any) => {
                console.log(data);    
                this.DataForm.get("Email").patchValue(data.Bus_email);
                this.DataForm.get("Phone").patchValue(data.Bus_phone);                          
                this.DataForm.patchValue(data);                        
                this._filter("");
                return false;                
              },
              err => {
                let errstr = this.hardfunc.getError(err);
                this.NotificationService.warn(errstr);
              }
            );                        
      }
    });
  }
  ItemTotal(){
    let index: number = 0;
    let formarray = (this.DataForm.controls['SubDatas'] as FormArray);            
      formarray.value.map(d => {
        let formgroup = (formarray.at(index) as FormGroup);
        let itemTot = formgroup.get("Qnty").value * formgroup.get("Rate").value 
        formgroup.get("Ittot").patchValue(itemTot);
        index++;
      });
  }
  GetPartyDetail() {
    this.commonservice.GetPartyDetail(this.DataForm.get("Pmst_cod").value, this.hardfunc.Firmx)
      .subscribe(
        (data: any) => {
          var data1 = data[0];          
          this.DataForm.get("Party_nm").patchValue(data1.PARTY_NAME.trim());
          this.DataForm.get("Party_country").patchValue(data1.PARTY_CITY.trim());
          this.DataForm.get("Email").patchValue(data1.EMAIL.trim());
        },
        err => {

        }
      )
  }
  GetLastNo(){
    this.POService.GetLastPono()
      .subscribe((data: any) => {        
        if(this.ReceivedData.id < 0){          
          this.DataForm.get("Pono").patchValue(data+1);          
        }        
      },
      err => {
        this.loading = false;
        let errstr = this.hardfunc.getError(err);
        this.NotificationService.warn(errstr);
      });
  }
  PaymentTerms(){
    this.loading = true;
    this.commonservice.CodeList('ImpoTerms')
      .subscribe(
        (data: any) => {          
          this.PayTerms = JSON.parse(data);            
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn(errstr);
        }
      );
  }
  GetHandles() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=D";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.DirectorList = data1;        
      }, err => {
        console.log(err);
      });
  }
  GetCountry(){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/GetCountry"
    this._http.post(url, null, { headers: this.hardfunc.PostHeaders() })
      .subscribe((data: any) => {
        this.CountryData = data;        
      }, err => {
        console.log(err);
      });
  }
  DocuCheck(data: any){    
    if(data.Qnt > 0){
      data.check = true;
    }else{
      data.check = false;
    }
  }
  OnCheck(data: any){    
    if(data.check === true){      
      data.Qnt = 1;
    }else{
      data.Qnt = 0;
    }
  }
  // GetAllProducts(){
  //   this.loading = true;
  //   this.ProductList = [];
  //   this.POService.GetAllProduct()
  //     .subscribe((data: any) => {          
  //       this.loading = false;       
  //       this.ProductList = data;
  //       console.log(this.ProductList);
  //       this.ProductList = this.ProductList.map((x) => {
  //         return {
  //           nm: x.Short_nm.trim(), //+'-'+ x.Party_city.trim() + '-'+ x.Party_Stat.trim()
  //           id: x.Product_id
  //         }
  //       });               
  //     }, err => {
  //       console.log(err);
  //     });
  // }
  GetPartyList() {
    this.loading = true;
    this.PartyList = [];
    let data: any = {};    
    data.Firmx = this.hardfunc.Firmx;
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/partysAPI/GetDatas";
    this._http.post(url, null, this.repmodel)
      .subscribe((data1: any) => {        
        this.loading = false;        
        this.PartyList = data1;        
        this.PartyList = this.PartyList.map((x) => {
          return {
            nm: x.Party_name.trim(), //+'-'+ x.Party_city.trim() + '-'+ x.Party_Stat.trim()
            id: x.Party_id,            
          }          
        });
        this.ProductListOfParty();                  
        this._filter(""); 
      }, err => {
        console.log(err);
      });
  }
  addItems() {
    (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubData());
    this.Party_auto();
    this.ProductListOfParty();
  }
  DeleteRow(i: number) {
    if(this.ReceivedData.id > 1)
      {        
        console.log(this.ReceivedData.id);
        let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
        let formgroup = (formarray.at(i) as FormGroup);
        formgroup.get('Noted').patchValue('D');              
      }
      else{
        (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);        
      }  
  }
  
  PO_Documents() {
    this.loading = true;
    this.commonservice.CodeList('PO_Docu')
      .subscribe(
        (data: any) => {          
          this.Documents = JSON.parse(data);          
          this.Documents.map(d => {
            d.check = false;
            d.Qnt = 0;
          });          
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.NotificationService.warn(errstr);
        }
      );
  }
  ResetData() {
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    else {
    this.DataForm.reset();
    this.DataForm.patchValue(this.initialValues);
    Object.keys(this.DataForm.controls).forEach(key => {
      this.DataForm.get(key).setErrors(null);
    });
    }
  }
  CreateFormGroup() {
    return this.fb.group({   
      Pk_id: new FormControl(-1),
      Firm: new FormControl('NFF'),
      Party_id: new FormControl(),
      Party_nm: new FormControl('',Validators.required),      
      Party_country: new FormControl(''),
      Pono: new FormControl(),
      
      Address: new FormControl(''),
      Email: new FormControl(''),
      Phone: new FormControl(''),
      Person1: new FormControl(),
      Email1: new FormControl(''),
      Phone1: new FormControl(''),

      Podt: new FormControl(this.formatDate(new Date())),
      Toitm: new FormControl(),
      Bsctot: new FormControl(),
      Invtot: new FormControl(),      
      Payment: new FormControl(),      
      Delivery: new FormControl('',Validators.maxLength(100)),
      Freight: new FormControl('',Validators.maxLength(100)),
      Ordby: new FormControl(),
      Year: new FormControl(this.hardfunc.Yrx),
      Curr: new FormControl('',[Validators.maxLength(15),Validators.required]),
      Convrate: new FormControl(),
      Paydays: new FormControl(),
      Cantag: new FormControl(),
      Canrem: new FormControl('',Validators.maxLength(100)),
      Dir_id: new FormControl(6),
      Conditions: new FormControl(),
      Reqdt: new FormControl(),
      Dirpono: new FormControl(),  
      Po_Att: new FormControl(''),
      Auth_by: new FormControl('',Validators.maxLength(20)),
      Documents: new FormControl('',Validators.maxLength(500)),
      Freightmode: new FormControl('',Validators.maxLength(10)),
      Loadingport: new FormControl('',Validators.maxLength(50)),
      Loadingcountry: new FormControl(Validators.maxLength(50)),
      Dischargeport: new FormControl('',Validators.maxLength(50)),      
      Dischargecountry: new FormControl(Validators.maxLength(50)),
      Revno: new FormControl(0), 
      RevDate: new FormControl(),
      Status: new FormControl('D'),
      Prepared: new FormControl(''),
      EditRevise: new FormControl(''), // Only For Revise
      SubDatas: this.fb.array([this.CreateSubData()]),
    });
  }
  CreateSubData(): FormGroup {
    return this.fb.group({      
      PoPO_pkid: new FormControl(-1),
      Pk_id: new FormControl(-1),
      Srno: new FormControl(0),
      Itcode: new FormControl(),
      Product_nm: new FormControl(''),
      HsnCode: new FormControl(''),
      Qnty: new FormControl(),      
      Rate: new FormControl(),      
      Bsc: new FormControl(),      
      Ittot: new FormControl(),  
      Noted: new FormControl(''),
      Year: new FormControl(this.hardfunc.Yrx),      
    });
  }
  private formatDate(date) {       
    const d = new Date(date);    
    let month = '' + (d.getMonth() + 1);    
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;    
    return [year, month, day].join('-');
  }
  private _filter(value: string) {
    if (!value)
    {
      this.filteredOptions = this.PartyList;
      return;
    }
    if (value.length <= 0) {
      this.filteredOptions = this.PartyList;
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredOptions = this.PartyList.filter(option => option.nm.toLowerCase().includes(filterValue));
  }
  private _Prdfilter(value: string) {                      
    if (!value)
    {
      this.filteredItems = this.ProdListofParty;
      return;
    }
    if (value.length <= 0) {
      this.filteredItems =  this.ProdListofParty;
      return;
    }    
    const filterValue = value.toLowerCase();
    this.filteredItems = this.ProdListofParty.filter(option => option.nm.toLowerCase().includes(filterValue));       
  }  
}
