import { Component, OnInit, Optional, Inject } from '@angular/core';
import { PurchaseService } from '../purchase.service';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-add-items-grn',
  templateUrl: './add-items-grn.component.html',
  styleUrls: ['./add-items-grn.component.css']
})
export class AddItemsGrnComponent implements OnInit {

  PoItems: any = [];
  msgFromChild1 = [];

  constructor(private PurchaseService: PurchaseService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
  ) { }

  ngOnInit() {
    this.AddToGrn();
  }
  AddToGrn(){
      this.PurchaseService.PartyPoItems(this.ReceivedData.id)
        .subscribe((data: any) => {
          console.log(data);
          this.PoItems = data;
          this.PoItems.map(d => {
            d.CHKYN = 0 ? "true" : "false";
          });
        });
  }
  msgToParent(data: any) {    
    this.msgFromChild1.push(data);    
  }
}
