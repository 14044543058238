import { Component, OnInit, Optional, Inject } from '@angular/core';
import { ManufacturingService } from '../manufacturing.services';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { PrdHistoryComponent } from '../stk-repo/prd-history/prd-history.component';
import { MasterService } from '../../master/master.service';

interface Tree {
  root: TreeNode;
}

interface TreeNode {
  label: string;
  children: TreeNode[];
}
@Component({
  selector: 'app-bomstock',
  templateUrl: './bomstock.component.html',
  styleUrls: ['./bomstock.component.css']
})
export class BomstockComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  OrdData: any = [];
  msgFromBOM1 = [];
  isClicked = false;

  public data: Tree;
  public selectedTreeNode: TreeNode | null;

  constructor(private manufacturingservice: ManufacturingService,
              private hardfunc: HardFuncService,
              private notificationservice: NotificationService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private MasterService: MasterService) { }

  ngOnInit() {
    this.GetData();
  }

  msgToParent(no_of_msg: number) {
    this.msgFromBOM1.push(no_of_msg);
  }
  GetData() {
    this.loading = true;
    this.datalist = [];
    if (this.ReceivedData) {
      this.manufacturingservice.GetBOMStock(this.ReceivedData.product_id, this.ReceivedData.Product_nm)
        .subscribe(
          (data1: any) => {        
            console.log(data1);
            let P_name = '###';
            this.OrdData = [];
            data1.map(m => {
              if (m.En === 'Y') {
                m.Check = true;
              }
              if (m.Fprod_id === this.ReceivedData.product_id) {
                m.ShowYN = true;
                m.Expand = false;
              }
              else {
                m.ShowYN = false;
                m.Expand = false;
              }
              m.Header = 1;
              this.OrdData.push(m);
            });            
          },
          err => {
            let errstr = this.hardfunc.getError(err);
            this.notificationservice.warn(errstr);
          }
        )
    }
  }
  StockYN(Product_id: number, yesno: boolean) {
    let str1: string
    if (yesno === true) {
      str1 = 'Y';
    } else {
      str1 = 'N';
    }
    this.loading = true;
    const dialogRef = this.manufacturingservice.Ignore_Items(Product_id, str1)
      .subscribe(
        data => {
          this.notificationservice.success(" Data saved Successfully... ");
        },
        err => {
          console.log(err);
          this.notificationservice.warn("Error in deleting data ...");
        }
      )
  }
  ProductUpd(Product_id: number, Short_nm: string) {
    console.log(Product_id);
    console.log(Short_nm);
    this.MasterService.ProductUpd(Product_id, Short_nm)
      .subscribe((data: any) => {
        this.notificationservice.success("Product Updated Successfully... ");
        this.GetData();
      });
  }
  Expand(data) {

    data.Expand = !data.Expand;
    data.ChildData.map(m => {
      if (m.En === 'Y') {
        m.Check = true;
      }
      m.Expand = false;
      m.ShowYN = data.Expand;
    });
  }
  UpdateSrno(Product_id: number,Fprod_id: number,Srno: number){        
    this.manufacturingservice.UpdateSrno(Product_id, Fprod_id, Srno)
      .subscribe((data: any) => {
        console.log(data);
      });     
  }
  DownloadPDF() {
    this.loading = true;
    this.datalist = [];
    if (this.ReceivedData) {
      this.manufacturingservice.DownloadPDF(this.ReceivedData.product_id, this.ReceivedData.Product_nm)
        .subscribe(
          (data1: any) => {
            this.loading = false;
            console.log(data1);
            const data = window.URL.createObjectURL(data1);
            var link = document.createElement('a');
            link.href = data;
            link.target = "_blank";
            link.download = "BOM Item.pdf";
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);

          },
          err => {
            let errstr = this.hardfunc.getError(err);
            this.notificationservice.warn(errstr);
          }
        )
    }
  }
  WithoutBOMPDF() {
    this.loading = true;
    this.datalist = [];
    if (this.ReceivedData) {
      this.manufacturingservice.WithoutBOMPDF(this.ReceivedData.product_id, this.ReceivedData.Product_nm)
        .subscribe(
          (data1: any) => {
            this.loading = false;
            console.log(data1);
            const data = window.URL.createObjectURL(data1);
            var link = document.createElement('a');
            link.href = data;
            link.target = "_blank";
            link.download = "BOM Item.pdf";
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);

          },
          err => {
            let errstr = this.hardfunc.getError(err);
            this.notificationservice.warn(errstr);
          }
        )
    }
  }
  PrdHistoryDialog(Product_id: number, Short_nm: string,Store_id: number): void {
		const dialogRef = this.dialog.open(PrdHistoryComponent, {
			width: '1200px',
      data: { id: Product_id, Dept_id: Store_id, Short_nm: Short_nm }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }  
}
