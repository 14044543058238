import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { PurchaseService } from '../../purchase.service';
import { PoGenComponent } from '../po-gen.component';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-po-party-list',
  templateUrl: './po-party-list.component.html',
  styleUrls: ['./po-party-list.component.css']
})
export class PoPartyListComponent implements OnInit {

  PartyList: any = [];  

  constructor(private PurchaseService: PurchaseService,
              private dialog: MatDialog,
              private hardfunc: HardFuncService,
              private NotificationService: NotificationService,
              public router: Router, ) { }

  ngOnInit() {
    this.GetData();
    // this.DataForm.get('Party_name').valueChanges.subscribe(response => {
    //   this._filter(response);
    // }); 
  }
  GetData(){
    this.PurchaseService.PoPartyList()
      .subscribe((data: any) => {        
        this.PartyList = data;
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        this.NotificationService.warn(errstr);
      }
    );  
  }
  // POGenerate(data: any, Status: string){
  //   Redi_quote(Srno: number){
	// 	this.router.navigate(['/sales/Quote-crud/'+Srno]);
	// }
  // }
  Redi_GenPo(Party_id: number){
		this.router.navigate(['/purchase/po-man/'+Party_id]);
	}
  
}
