import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/Services/Common/common.service';
import { MatSnackBar, MatTableDataSource, MatDialog } from '@angular/material';
import { PrdgroupCreateComponent } from './prdgroup-create/prdgroup-create.component';
import { AdminService } from '../../admin/admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { ManufacturingService } from '../manufacturing.services';
import { MatPaginator } from '@angular/material';

@Component({
  selector: 'app-prdgroup-crud',
  templateUrl: './prdgroup-crud.component.html',
  styleUrls: ['./prdgroup-crud.component.css']
})
export class PrdgroupCrudComponent implements OnInit {

  PrdGroupList: any = [];
  loading: boolean = false;
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private _cservice: CommonService,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog,
              private adminservice: AdminService,
              private notificationservice: NotificationService,
              private hardfunc: HardFuncService,
              private mnfservice: ManufacturingService) { }

  ngOnInit() {
    this.ItemGroup();
  }
  DisplayCol(){
    this.displayedColumns = ['grp_id','grp_nm','fProd_id','tProd_id','Action'];
  }
  ItemGroup(){
    this._cservice.PrdGroupListAll()
      .subscribe(data => {
        this.PrdGroupList = data;        
        this.dataSource = new MatTableDataSource(<any>this.PrdGroupList);
        this.DisplayCol();
        this.loading = false;
        this.dataSource.paginator = this.paginator;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });
  }
  OpenDialog(grp_id: number, grp_nm: string){
    const dialogRef = this.dialog.open(PrdgroupCreateComponent, {
      width: '1000px',
      height: '615px',
      data: { Grp_id: grp_id, Grp_nm: grp_nm }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.ItemGroup();
    });
  }
  CreatePrdGroup(grp_id: number): void {    
    const dialogRef = this.dialog.open(PrdgroupCreateComponent, {
      width: '1000px',
      height: '615px',
      data: { Grp_id:grp_id }   
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.ItemGroup();
    });
  }
  DeletePrdgrp(grp_id: number){
    this.adminservice.openConfirmDialog("Are you sure, You want to delete this ?")
      .afterClosed().subscribe(res => {
        if(res){
          this.loading = true;
          const dialogRef = this.mnfservice.deletePrdgrp(grp_id)
            .subscribe((data: any) =>{
              this.loading = false;
              this.notificationservice.warn("Data Deleted Successfully");
              this.ItemGroup();
            },
            err => {
              console.log(err);
              this.loading = false;
              let errstr = this.hardfunc.getError(err);
              this.notificationservice.warn(errstr);
            }
            )
        }
      });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
