import { Component, OnInit } from '@angular/core';
import { ManufacturingService } from '../../manufacturing/manufacturing.services';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { Sort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { PurchaseService } from '../purchase.service';
import * as moment from 'moment'

@Component({
  selector: 'app-grn-rcvd',
  templateUrl: './grn-rcvd.component.html',
  styleUrls: ['./grn-rcvd.component.css']
})
export class GrnRcvdComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  DataForm: FormGroup;
  Indenters: any = [];

  constructor(private ManufacturingSer: ManufacturingService,
              private hardfunc: HardFuncService,
              private notificationservice: NotificationService,
              private fb: FormBuilder,
              private purchaseservice: PurchaseService,
              private MatSnackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.loading = true;
    this.DataForm = this.createFormGroup();
    this.ReceivedGRN();
    this.GetIndeter();
  }
  DisplayCol() {	
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['Pono','Grnno','Invdts','Srno','Party_name','short_nm','Qnty','Pqnty','dqnty','Edqnty','Bqnty','Remark'];
	}
  ReceivedGRN(){
    this.loading = true;
    let data: any = {};
    let cnd = "";

    //and a.RcptDt >= ''20240423'' and  a.RcptDt <= ''20240423''
    cnd += " and a.RcptDt >= ''"+moment(this.DataForm.get("Podts").value).format("yyyyMMDD")+"'' ";
    cnd += " and a.RcptDt <= ''"+moment(this.DataForm.get("Tdate").value).format("yyyyMMDD")+"'' ";
    // cnd += " and a.Option = 'D' ";
    if(this.DataForm.get("Indenter").value != "*All*"){
      cnd += " and Indter = '' "+ this.DataForm.get("Indenter").value +" '' "
    }
    data.Cond = cnd;
    data.Division = this.DataForm.get("Division").value;
    data.Status = "P";        

    this.ManufacturingSer.ReceivedGRN(data)
    .subscribe(data => {      
      this.datalist = data;            
      this.dataSource = new MatTableDataSource(<any>this.datalist);
      this.loading = false;
      this.DisplayCol();      
    },
    err=> {
      this.loading = false;
      let errstr =  this.hardfunc.getError(err);
      console.log(err);
      this.notificationservice.warn(errstr);
      
    }); 
  }
  GetIndeter() {
    this.loading = true;
    this.Indenters = [];
    
    this.purchaseservice.getIndenterList()
      .subscribe(
        (data: any) => {          
           this.Indenters = data;           
          this.loading = false;
        },
        err => {
          this.loading = false;
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          }
        }
      )
  }
  openSnackBar(message: string, action: string) {
    this.MatSnackBar.open(message, action, {
      duration: 2000,
    });
  }
  createFormGroup(){
    return this.fb.group({
      // Party_name: new FormControl(''),      
      Podts: new FormControl(new Date()),
      Tdate: new FormControl(new Date()),
      Indenter: new FormControl("*All*"),
      Division: new FormControl("*All*"),
      // Option: new FormControl('D')
    });
  }
}
