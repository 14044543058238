import { DebugElement, Injectable } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { MatDialog } from '@angular/material';
import { DialogComponent } from './role-management/dialog/dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  
  getheaders;
  postheaders;
  msg: string;
  
  constructor(private hardfunc:HardFuncService ,
      private http:HttpClient,
      private dialog:MatDialog
     
    ) { 
      this.getheaders = new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
      })
      this.postheaders = new HttpHeaders({
        'content-Type': 'application/json',
        'process-Data': 'false',
        'Accept': 'application/json',      
        'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
      })

    }

    getUserbyUsername(UserName:string) {
        let url = this.hardfunc.BaseAPIUrl+"api/UsersAPI/getUserbyUsername?Username="+UserName;
        return this.http.get(url,  { headers: this.getheaders });
    }

    getAllUsers(){
      let url = this.hardfunc.BaseAPIUrl + "api/UsersAPI/GetAllUsers";
      return this.http.get(url,{headers:this.hardfunc.GetHeaders()});
    }
    getAllModuleUsers(){
      let url = this.hardfunc.BaseAPIUrl + "api/admin/modulessAPI/GetAllUsers";
      return this.http.get(url,{headers:this.hardfunc.GetHeaders()});
    }
    getFusermastData(){
      let url = this.hardfunc.BaseAPIUrl + "api/admin/usermastsAPI/GetDatas";
      return this.http.post(url, null, {headers: this.hardfunc.PostHeaders()});
    }
    deleteUserbyUsername(UserName:string) {
      let url = this.hardfunc.BaseAPIUrl+"api/UsersAPI/DeleteUser?Username="+UserName;
      return this.http.get(url,  { headers: this.getheaders });
    }

    /* Start Role Management */
    getAllRoles() {
      let url = this.hardfunc.BaseAPIUrl + "api/UsersAPI/getAllRoles";
        return this.http.get(url, {headers:this.hardfunc.GetHeaders()});
    }

    SaveRole(data: any) {
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      let url = this.hardfunc.BaseAPIUrl + "api/UsersAPI/SaveRole"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }

    GetRole(id:number) {

      let url = this.hardfunc.BaseAPIUrl+"api/UsersAPI/getRole?id="+id;
      return this.http.get(url,  { headers: this.getheaders });
    }

    DeleteRole(id:string) {
      let url = this.hardfunc.BaseAPIUrl+"api/UsersAPI/DeleteRole?id="+id;
      return this.http.post(url,  { headers: this.getheaders });
    }
    /* End Role Management */

    /* Start Module Management */
    getAllModules() {
      let url = this.hardfunc.BaseAPIUrl + "api/Admin/WmodulesAPI/GetDatas";
        return this.http.get(url,{headers:this.hardfunc.GetHeaders()});
    } 

    getAllModulesbyUser(username: string) {
       let url = this.hardfunc.BaseAPIUrl + "api/Admin/WmodulesAPI/getAllModulesbyUser?username="+username;
       return this.http.get(url,{headers:this.hardfunc.GetHeaders()});
    }
    
    SaveDatas(data: any) {
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      let url = this.hardfunc.BaseAPIUrl + "api/Admin/WmodulesAPI/SaveData"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    
    GetDatabyId(Wmodule_id:number) {
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/GetDatabyId?id="+Wmodule_id;
      return this.http.get(url,  { headers: this.getheaders });
    }
    DeleteModule(Wmodule_id:number) {
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/DeleteData?id="+Wmodule_id;
      return this.http.post(url,  { headers: this.getheaders });
    }
  
  
    getModulesbyUserAndParent_id(username:string, id:number) {
      let url = this.hardfunc.BaseAPIUrl + "api/Admin/WmodulesAPI/getModulesbyUserAndParent_id?Username="+username+"&Parent_id="+id;
        return this.http.get(url,{headers:this.hardfunc.GetHeaders()});
    } 

    getModulesbyUserAndWmodule_id(username:string, id:number) {
      let url = this.hardfunc.BaseAPIUrl + "api/Admin/WmodulesAPI/getModulesbyUserAndWmodule_id?Username="+username+"&Wmodule_id="+id;
        return this.http.get(url,{headers:this.hardfunc.GetHeaders()});
    } 


    GetPermissionbyModule_id(id: any) {
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/getAllUsers?wmodule_id="+id;
      return this.http.get(url, { headers: this.getheaders });
    }


    GetPermissionbyUserModule_id(usr:string,id: any) {
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/GetPermissionbyUserModule_id?username="+usr+"&wmodule_id="+id;
      return this.http.get(url, { headers: this.getheaders });
    }
    
    // API Test -- not done
    GetModulePermmsionbyUser(usr:string){
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/GetModulePermmsionbyUser?username="+usr;
      return this.http.get(url, { headers: this.getheaders });
    }

    GetUserPermissionbyRole_id(id:string){
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/GetUserPermissionbyRole_id?Role_id="+id;
      return this.http.get(url, { headers: this.getheaders });
    }

    GetModulePermissionbyRole_id(id:string){
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/GetModulePermissionbyRole_id?Role_id="+id;
      return this.http.get(url, { headers: this.getheaders });
    }

    
    GetUserPermissionbyModule_id(id:string){
      console.log(id);
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/GetUserPermissionbyModule_id?Wmodule_id="+id;
      return this.http.get(url, { headers: this.getheaders });
    }

    GetUserPermissionForDocu(){
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/GetUserPermissionbyModule_id?Wmodule_id="+1125;
      return this.http.get(url, { headers: this.getheaders });
    }
    
    GetDocuRightsbyId(Wmodule_id: number, Codemast_id: number,Omodule_id: number){
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/DocuRightsPermission?Wmodule_id="+Wmodule_id + 
                "&Codemast_id=" + Codemast_id + "&Omodule_id=" + Omodule_id;
      return this.http.get(url, { headers: this.getheaders });
    }

    GetDocuRightsbyIdMenu(){
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/DocuRightsPermission?Wmodule_id="+1125 + "&Codemast_id=" + 10;
      return this.http.get(url, { headers: this.getheaders });
    }

    //Fmodule --Get User Permission by Module
    GetUserPermissionbyFModule_id(id:string){
      let url = this.hardfunc.BaseAPIUrl+"api/admin/modulessAPI/GetUserPermissionbyModule_id?fmodule_id="+id;
      return this.http.get(url, { headers: this.getheaders });
    }
    
    GetRolePermissionbyModule_id(id:string){
      console.log(id);
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/GetRolePermissionbyModule_id?Wmodule_id="+id;
      return this.http.get(url, { headers: this.getheaders });
    }
    
    GetRolePermissionbyUser(id:string){
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/GetRolePermissionbyUser?Role_id="+id;
      return this.http.get(url, { headers: this.getheaders });
    }

    // Save Data
    SavePermissionbyModule_id(id: any, data: any) {
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/SavePermissionbyModule_id?wmodule_id="+id;
      return this.http.post(url,data, { headers: this.getheaders }); 
    }

    // Save Data -- Fmodule 
    SavePermissionbyFModule_id(id: any, data: any) {
      let url = this.hardfunc.BaseAPIUrl+"api/admin/modulessAPI/SavePermissionbyModule_id?Fmodule_id="+id;
      return this.http.post(url,data, { headers: this.getheaders });
    }
    
    SavePermissionbyFUsermastModule(Username: string, data: any) {
      let url = this.hardfunc.BaseAPIUrl+"api/admin/usermastsAPI/SavePermissionbyUsername?Username="+Username;
      return this.http.post(url,data, { headers: this.hardfunc.PostHeaders() });
    }

    SavePermissionbyUser(user:string, data: any) {
      let url = this.hardfunc.BaseAPIUrl+"api/Admin/WmodulesAPI/SavePermissionbyUser?username="+user;
      return this.http.post(url,data, { headers: this.getheaders });
    }

   


    // IP Address
    getIP()
    {
        let url = "https://api.ipify.org?format=json";
        return this.http.get(url);

    }



    //

    // API Test -- not done.
    DeleteRc(Rc_id: number) {
      let url = this.hardfunc.BaseAPIUrl+"api/Sales/rc_mastsAPI/DeleteData?id="+Rc_id;
      return this.http.post(url,null,  { headers: this.hardfunc.PostHeaders() });
    }
    DeletePdc(Subcode_id: number) {
      let url = this.hardfunc.BaseAPIUrl+"api/PdcAPI/DeleteData?id="+Subcode_id;
          return this.http.post(url,null,  { headers: this.hardfunc.PostHeaders() });
    }
    DelImpItem(Product_id: number) {
      let url = this.hardfunc.BaseAPIUrl+"api/ProductsAPI/DelImpPrd?Product_id="+Product_id;
          return this.http.post(url,null,  { headers: this.hardfunc.PostHeaders() });
    }
    DelProdFam(Subcode_id: number) {      
      let url = this.hardfunc.BaseAPIUrl + "api/Common/CodemastsAPI/DelSubcode?id=" + Subcode_id
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });      
    }
    AuthPO(Pono: number) {
      let url = this.hardfunc.BaseAPIUrl+"api/PoregAPI/PO_Authenticate?Pono="+Pono;
      return this.http.post(url, null,  { headers: this.hardfunc.PostHeaders() });
    }
    DeleteSpara(Spara_id: number) {
      let url = this.hardfunc.BaseAPIUrl+"api/Sales/sparasAPI/DeleteData?id="+Spara_id;
          return this.http.post(url,null,  { headers: this.hardfunc.PostHeaders() });
    }
    DeleteQuote1Item(Quote_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/Sales/quotesAPI/DeleteData?id=" + Quote_id
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    DeletePparabyId(Ppara_id: number){
      let url = this.hardfunc.BaseAPIUrl+"api/manufacturing/pparasAPI/DeleteData?id="+Ppara_id;
      return this.http.post(url,null,  { headers: this.hardfunc.PostHeaders() }); 
    }
    DeleteBG(Pk_id: number) {
      let url = this.hardfunc.BaseAPIUrl+"api/Sales/BGsAPI/DeleteData?id="+Pk_id;
          return this.http.post(url,null,  { headers: this.hardfunc.PostHeaders() });
    }
    DeleteEMD(Pk_id: number) {
      let url = this.hardfunc.BaseAPIUrl+"api/Sales/EMDsAPI/DeleteData?id="+Pk_id;
          return this.http.post(url,null,  { headers: this.hardfunc.PostHeaders() });
    }
    DeleteTGroup(Tgroup_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/Sales/tgroupsAPI/DeleteData?id="+Tgroup_id;
          return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    /* End Module Management */
    openConfirmDialog(msg){
      return this.dialog.open(DialogComponent,{
        width: '450px',
        panelClass: 'confirm-dialog-container',
        disableClose: true,
        data:{
          Message : msg
        }
      });
    }
    openWarningDialog(msg,p){
      return this.dialog.open(DialogComponent,{
        width: '450px',
        panelClass: 'confirm-dialog-container',
        disableClose: true,
        data:{
          Message : msg,
          forProd: p
        }
      });
    }
    openAlertDialog(msg,data){
      return this.dialog.open(AlertDialogComponent,{
        width: '313px',
        panelClass: 'confirm-dialog-container',
        disableClose: true,
        data:{
          Message : msg,
          EntryNo: data
        }
      });
    }
    getNotificationforDash(){
      var data = {
        Firmx:this.hardfunc.Firmx
      }
       let url = this.hardfunc.BaseAPIUrl+"api/Common/notificationssAPI/GetdashNoti";
       return this.http.post(url,data,  { headers: this.hardfunc.PostHeaders() });
    }
    getNotification(){
      var data = {
        Firmx:this.hardfunc.Firmx
      }
       let url = this.hardfunc.BaseAPIUrl+"api/Common/notificationssAPI/GetNotificationbyUser?username="+this.hardfunc.UserName;
       return this.http.post(url,data,  { headers: this.hardfunc.PostHeaders() });
    }
    getNotificationbyType(Vtype:string){            
       let data = {
        Firmx:this.hardfunc.Firmx,
        Vtype
      }
       let url = this.hardfunc.BaseAPIUrl+"api/Common/notificationssAPI/GetNotificationbyUserandType?username="+this.hardfunc.UserName;
       return this.http.post(url,data,  { headers: this.hardfunc.PostHeaders() });
    }
    getNotificationbyOnlyType(Vtype:string){            
      let data = {
       Firmx:this.hardfunc.Firmx,
       Vtype
     }
      let url = this.hardfunc.BaseAPIUrl+"api/Common/notificationssAPI/GetNotificationbyOnlyType?Type="+Vtype;
      return this.http.post(url,data,  { headers: this.hardfunc.PostHeaders() });
   }

    Delete_notification(Ordno: number){
      let url = this.hardfunc.BaseAPIUrl + "api/Common/notificationssAPI/DeleteData?id=" + Ordno;
		  return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    DeleteNotibyType(Vtype: string){
      let url = this.hardfunc.BaseAPIUrl + "api/Common/notificationssAPI/DeleteNotibyType?Vtype=" + Vtype;
		  return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    SaveFmodule(data: any){
      let url = this.hardfunc.BaseAPIUrl + "api/admin/modulessAPI/SaveData"
      return this.http.post(url, data, {headers: this.hardfunc.PostHeaders()});
    }
    GetFmoduleData(){
      let url = this.hardfunc.BaseAPIUrl + "api/admin/modulessAPI/GetDatas"
      return this.http.post(url, null, {headers: this.hardfunc.PostHeaders()});
    }
    GetDatafmodule(Pk_id: number){      
      let url = this.hardfunc.BaseAPIUrl + "api/admin/modulessAPI/GetDatabyId?id="+Pk_id;
      return this.http.post(url, null, {headers: this.hardfunc.PostHeaders()});
    }
    deletemodule(Pk_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/admin/modulessAPI/DeleteData?id="+Pk_id;
      return this.http.post(url, null, {headers: this.hardfunc.PostHeaders()});
    }

    SaveUmodule(data: any){
      let url = this.hardfunc.BaseAPIUrl + "api/admin/modulessAPI/SaveData"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
    }
    GetFModulePermmsionbyUser(Username: string){
      let url = this.hardfunc.BaseAPIUrl + "api/admin/usermastsAPI/GetFModulePermmsionbyUser?username=" + Username;
      return this.http.get(url, { headers: this.getheaders });
    }
    Createfusermast(data: any){
      let url = this.hardfunc.BaseAPIUrl + "api/admin/usermastsAPI/SaveData"
      return this.http.post(url, data, {headers: this.hardfunc.PostHeaders()});
    }
    GetDatafusermast(Pk_id: number){      
      let url = this.hardfunc.BaseAPIUrl + "api/admin/usermastsAPI/GetDatabyId?id="+Pk_id;
      return this.http.post(url, null, {headers: this.hardfunc.PostHeaders()});
    }
    DeleteUserfromfusermast(Pk_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/admin/usermastsAPI/Deletedata?id="+Pk_id;
      return this.http.post(url, null, {headers: this.hardfunc.PostHeaders()});
    }
    SaveChangepwd(data: any){
      let url = this.hardfunc.BaseAPIUrl + "api/UsersAPI/ResetPwd1"
      return this.http.post(url, data, {headers: this.hardfunc.PostHeaders()});
    }
    getsessiondata(data: any){
      let url = this.hardfunc.BaseAPIUrl + "api/Admin/sessionsAPI/getSessionData"
      return this.http.post(url, data, {headers: this.hardfunc.PostHeaders()});
    }
    SaveDocuRights(data: any){      
      let url = this.hardfunc.BaseAPIUrl + "api/Admin/WmodulesAPI/SaveDocuRights";
      return this.http.post(url, data, {headers: this.hardfunc.PostHeaders()});
    }
    AllSaveDocuRights(data: any){         
      let url = this.hardfunc.BaseAPIUrl + "api/Admin/WmodulesAPI/AllSaveDocuRights";
      return this.http.post(url, data, {headers: this.hardfunc.PostHeaders()});
    }
    DeleteTproduct(Tproduct_id: number, Vtype: string){
      let url = this.hardfunc.BaseAPIUrl + "api/Sales/tproductsAPI/DeleteData?id=" +Tproduct_id;
      return this.http.post(url, null, {headers: this.hardfunc.PostHeaders()});
    }
    TodaySOAutomail(){
      let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/SOAutomail?Firmx="+this.hardfunc.Firmx
      return this.http.get(url, { headers: this.getheaders });
    }
    GetCopyRights(FromUser: string, ToUser: string){      
      let url = this.hardfunc.BaseAPIUrl + "api/admin/usermastsAPI/CopyRights?FromUser=" + FromUser.trim() + "&ToUser=" + ToUser;
      return this.http.post(url, null, {headers: this.hardfunc.PostHeaders()});
    }
}

