import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { Sort, MatTableDataSource } from '@angular/material';
import { BgService } from '../bg.service';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { BgComponent } from '../bg.component';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import * as   XLSX from 'xlsx';
import { BgAttachmentComponent } from '../bg-attachment/bg-attachment.component';
import { PendordAttachComponent } from '../../pendord/pendord-attach/pendord-attach.component';
import { PendordService } from '../../pendord/pendord.service';
//import { XlsxExporterService } from 'mat-table-exporter';
import * as moment from 'moment';
import { BgEditnoteComponent } from '../bg-editnote/bg-editnote.component';

@Component({
	selector: 'app-bg-list',
	templateUrl: './bg-list.component.html',
	styleUrls: ['./bg-list.component.css']
})
export class BgListComponent implements OnInit, OnDestroy {

	datalist: any = [];
	loading: boolean = false;
	datasource: any = [];
	dataSource: MatTableDataSource<unknown>;
	displayedColumns: string[] = [];
	massage: string;
	DataForm: FormGroup;
	bg_banklist: any = [];
	bg_Type: any = [];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@Input('mode') mode;
	filename: string = 'BG-List.xlsx';
	Paid_Total: number;
	Noti_Array: any = [];
	no_of_msg: number = 0;
	BgReqBylist: any = [];

	constructor(private bgService: BgService,
		private _snackBar: MatSnackBar,
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private adminservice: AdminService,
		private hardfunc: HardFuncService,
		private commonservice: CommonService,
		private fb: FormBuilder,
		private pendordservice: PendordService) { }

	ngOnInit() {
		this.getBg_bank();
		this.getType();
		this.DataForm = this.createFormGroup();
		this.loading = true;
		this.getAttachment();
		this.getNotification();
		this.GetBgGrpby();
	}
	ngOnDestroy(): void {
		console.clear();
	}
	DisplayCol() {
		let isSm = window.matchMedia(`(max-width: 960px)`).matches;
		this.displayedColumns = ['App_no', 'Ordno', 'OrdTo', 'Reqby', 'Type_nm', 'Party_name', 'Party_pono', 'Party_podt', 'Amt', 'Bg_no', 'Bg_date', 'Bg_bank_nm', 'Bg_expdt', 'Bg_claimdt', 'BankChrg', 'Remarks', 'Action'];
	}

	//1. api/Sales/BGsAPI/GetDatas --GetData.
	getData() {
		this.loading = true;
		this.datalist = [];
		var data: any = {};
		let cnd = " 1=1 ";
		if (this.DataForm.value.Party_name.length > 0) {
			cnd += " and Party_name like ''%" + this.DataForm.value.Party_name + "%'' ";
		}
		if (this.DataForm.value.Party_pono.length > 0) {
			cnd += " and Party_pono like ''%" + this.DataForm.value.Party_pono + "%'' ";
		}
		if (this.DataForm.value.Type != "*All*") {
			cnd += " and Type = ''" + this.DataForm.value.Type + "''";
		}
		if (this.DataForm.value.Reqby != "*All*") {
			cnd += " and Reqby like ''%" + this.DataForm.value.Reqby + "%'' ";
		}
		if (this.DataForm.value.Bg_bank != "*All*") {

			if (this.DataForm.value.Bg_bank == "*K*") {
				//cnd += " and Bg_bank in (''33'',''34'',''35'') ";	
				cnd += " and b.vals like ''%KCC%'' ";
			}

			else {
				cnd += " and Bg_bank = ''" + this.DataForm.value.Bg_bank + "''";
			}
		}
		if (this.DataForm.value.Exp_till != "*All*") {			
			if (this.DataForm.value.Exp_till == "Today") {
				cnd += " and Bg_claimdt >= convert(datetime,getdate(),103) and  (Bg_Rtn is null or Bg_Rtn = ''N'')"
			} else if  (this.DataForm.value.Exp_till == "Exp") {
				cnd += " and  (Bg_Rtn is null or Bg_Rtn = ''N'') and Bg_claimdt <= convert(datetime,getdate(),103) ";
			} else if (this.DataForm.value.Exp_till == "Bg_Rtn"){				
				console.log("Ok");
				cnd += " and  (Bg_Rtn is null or Bg_Rtn = ''Y'') and Bg_claimdt <= convert(datetime,getdate(),103) ";
			} else if (this.DataForm.value.Exp_till == "CurrExp"){
				console.log("Ok");
				cnd += "  and  (Bg_Rtn is null or Bg_Rtn = ''N'')";
			} 
			// and Bg_claimdt >= convert(datetime,getdate(),103)	
		}
		data.cond = cnd;
		this.bgService.getDatas(data)
		.subscribe(
			data => {
				this.datalist = data;
				console.log(this.datalist);
				this.Paid_Total = 0;
				this.datalist.map(m => {
					this.Paid_Total += m.Amt;
				})
				this.loading = false;
				this.dataSource = new MatTableDataSource(<any>this.datalist);
				this.DisplayCol();
				this.getAttachment();
				this.getNumberOfchats();
				this.dataSource.paginator = this.paginator;
			},
			err => {
				if (err.error.error_description) {
					this.openSnackBar(err.error.error_description, "dismiss");
					//	this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err.error.error_description });
				}
				else {
					this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
					//this.msgs.push({ severity: 'error', summary: 'Error Message', detail: "Check server connectivity... " });
				}
			}
		)
	}

	GetBgGrpby(){
		this.bgService.GetbgByGrp()
			.subscribe((data: any) => {
				this.BgReqBylist = data;
			},
			err => {
				let errstr = this.hardfunc.getError(err);
				console.log(err);
				this.notificationService.warn(errstr);
			});
	}
	//2. api/DMSAPI/DocuSumbyOrdno --Get Document Sum By Order No.
	getAttachment() {
		this.pendordservice.GetDatabyAttach(this.hardfunc.Firmx, 'BG')
			.subscribe(				
				(data: any) => {					
					data.map(w => {
						if (w) {							
							var obj: any = this.datalist.filter((x: any) => x.Pk_id === w.Ordno && x.Oloc === x.Oloc)[0];
							if (obj) {
								obj.No_of_docu = w.No_of_docu;								
							}
						}
					});
				}
			);
	}

	//3. api/Common/notessAPI/NotesSumbyInvno --Notes Sum by InvNo.
	getNumberOfchats() {
		this.bgService.getNumberOfchats('BG')
		  .subscribe(
			(data: any) => {
			  data.map(w => {
				if (w)
				{
					var obj: any = this.datalist.filter((x: any) => x.Pk_id === w.Invno )[0];
					if (obj) {
						obj.No_of_chats = w.Cnt;
					}
				}
			  });
			}
		  );
	  }
	EditNote(Pk_id: number, Firm: string, Pmst_cod: string, party_name: string) {
		const dialogRef = this.dialog.open(BgEditnoteComponent, {
			width: '840px',
			height: '510px',
			data: { Invno: Pk_id, Firm: Firm, Pmst_cod: Pmst_cod, party_name: party_name }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}
	exportexcel(): void {

		// Option-2 Entire JSON
		const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.datalist);
		this.datalist.map(m => {
			m.Party_podt = moment(m.Party_podt).format('DD/MM/yyyy')
			m.Bg_date = moment(m.Bg_date).format('DD/MM/yyyy')
			m.Bg_expdt = moment(m.Bg_expdt).format('DD/MM/yyyy')
			m.Bg_claimdt = moment(m.Bg_claimdt).format('DD/MM/yyyy')
		})
		// Option-3 Selected fields from JSON
		let newData = this.datalist.map(({ App_no, Party_name, Amt, Bg_bank_nm, Bg_no, Bg_date, Bg_expdt, Bg_claimdt }) =>
			({ App_no, Party_name, Amt, Bg_bank_nm, Bg_no, Bg_date, Bg_expdt, Bg_claimdt }));
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData);

		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		XLSX.writeFile(wb, this.filename);
	}

	OpenInvoice(Invno: number) {
		this.dataSource.filter = "id#"+Invno.toString();
	}

	//5. api/Common/notificationssAPI/GetNotificationbyUserandType --Get Notification
	getNotification() {
		this.Noti_Array = [];
		this.adminservice.getNotificationbyType('BG')
			.subscribe(
				(data: any) => {
					this.Noti_Array = data;
					this.no_of_msg = 0;
					this.Noti_Array.map(m => {
						if (m.Msg != null) {
							this.no_of_msg += 1;
							let x = m.Msg.split('#&#');
							m.MsgHeader = x[0];
							m.MsgBody = x[1];
							m.MsgFooter = x[2];
						}
					});
				},
				err => {
					let errstr = this.hardfunc.getError(err);
					console.log(err);
					this.notificationService.warn(errstr);
				}
			)
	}

	//6. api/Common/notificationssAPI/DeleteData --Delete Notification.
	Delete_Noti(Notification_id: number): void {
		this.loading = true;
		this.adminservice.Delete_notification(Notification_id)
			.subscribe(
				(data: any) => {
					this.loading = false;
					this.getNotification();
				},
				err => {
					this.loading = false;
					let errstr = this.hardfunc.getError(err);
					console.log(err);
				}
			)
	}

	//7. api/Common/CodemastsAPI/GetCodeListbyCodenm --Get Code master by Bank Guarantee.
	getBg_bank() {
		this.loading = true;
		this.commonservice.CodeList('BG_BANK')
			.subscribe(
				(data: any) => {
					this.bg_banklist = JSON.parse(data);
					this.loading = false;
				},
				err => {
					this.loading = false;
					let errstr = this.hardfunc.getError(err);
					console.log(err);
					this.notificationService.warn(errstr);
				}

			);
	}

	//8. api/Common/CodemastsAPI/GetCodeListbyCodenm --Get BG Type.
	getType() {
		this.loading = true;
		this.commonservice.CodeList('BG_TYPE')
			.subscribe(
				(data: any) => {
					this.bg_Type = JSON.parse(data);
					this.loading = false;
				},
				err => {
					this.loading = false;
					let errstr = this.hardfunc.getError(err);
					console.log(err);
					this.notificationService.warn(errstr);
				}
			);
	}

	createFormGroup() {
		return this.fb.group({
			Party_name: new FormControl(''),
			Party_pono: new FormControl(''),
			Bg_bank: new FormControl('*All*'),
			Type: new FormControl('*All*'),
			Exp_till: new FormControl('*All*'),
			Reqby: new FormControl('*All*'),
		});
	}
	openAttachment(Pk_id: number, Vtype: string): void {
		const dialogRef = this.dialog.open(PendordAttachComponent, {
			width: '925px',
			height: '600px',			
			data: { Ordno: Pk_id, Oloc: 1, Vtype: Vtype, Wmodule_id: 1138  }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}

	openDialog(Pk_id: number): void {
		const dialogRef = this.dialog.open(BgComponent, {
			width: '1100px',
			data: { id: Pk_id }
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
		});
	}

	//9. api/Sales/BGsAPI/DeleteData --Delete BG.
	DeleteRcd(Pk_id: number): void {
		this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
			.afterClosed().subscribe(res => {
				if (res) {
					this.loading = true;
					const dialogRef = this.adminservice.DeleteBG(Pk_id)
						.subscribe(
							(data: any) => {
								this.loading = false;
								this.notificationService.warn("Data deleted succefully");
								this.getData();
							},
							err => {
								this.loading = false;
								let errstr = this.hardfunc.getError(err);
								console.log(err);
								this.notificationService.warn(errstr);
							}
						)

					// this.notificationService('Deleted Successfully');
				}
			});
	}
	sortData(sort: Sort) {
		const data = this.datalist.slice();
		if (!sort.active || sort.direction === '') {
			this.dataSource = data;
			return;
		}

		this.dataSource = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'Party_name': return compare(a.Party_name, b.Party_name, isAsc);
				case 'App_no': return compare(a.App_no, b.App_no, isAsc);
				case 'Reqby': return compare(a.Reqby, b.Reqby, isAsc);
				case 'Type_nm': return compare(a.Type_nm, b.Type_nm, isAsc);
				case 'Party_pono': return compare(a.Party_pono, b.Party_pono, isAsc);
				case 'Party_podt': return compare(a.Party_podt, b.Party_podt, isAsc);
				case 'Bg_date': return compare(a.Bg_date, b.Bg_date, isAsc);
				case 'Bg_expdt': return compare(a.Bg_expdt, b.Bg_expdt, isAsc);
				case 'Bg_claimdt': return compare(a.Bg_claimdt, b.Bg_claimdt, isAsc);
				default: return 0;
			}
		});
	}

	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}
}
function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
