import { Injectable } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ManufacturingService {
  getheaders;
  postheaders
  constructor(
      private hardfunc:HardFuncService ,
      private http:HttpClient,
    ) { 
      this.getheaders = new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
      })
      this.postheaders = new HttpHeaders({
        'content-Type': 'application/json',
        'process-Data': 'false',
        'Accept': 'application/json',      
        'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
      })
    }

    GetPrdGroup1(data:any) {
        var headers = new HttpHeaders({
          'content-Type': 'application/json',
          'process-Data': 'false',
          'Accept': 'application/json',      
          'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
        data.Firmx = this.hardfunc.Firmx;
        data.Yrx = this.hardfunc.Yrx;
        let url = this.hardfunc.BaseAPIUrl+"api/PrdGroupsAPI/GetPrdGroup1"
        return this.http.post(url, data, { headers: headers });
    }



    onPrdStock(data:any) {
      var headers = new HttpHeaders({
        'content-Type': 'application/json',
        'process-Data': 'false',
        'Accept': 'application/json',      
        'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
      })
      let url = this.hardfunc.BaseAPIUrl+"api/StoreAPI/PrdStock"
      return this.http.post(url, data, { headers: headers });
  }

    //api/StoreAPI/PrdStock

    GetBOM(Product_id: number,Product_nm: String, Dept_id:number){
      let data: any = {};
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Product_id = Product_id;
      data.Product_nm = Product_nm;
      data.Dept_id = Dept_id;

      let url = this.hardfunc.BaseAPIUrl + "api/Manufacturing/bomsAPI/GetDatas1?Prodgrp_id=" + Product_id;
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }

    PrdIdbyBom(Product_id: number){
      let data: any = {};
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Product_id = Product_id;      

      let url = this.hardfunc.BaseAPIUrl + "api/Manufacturing/bomsAPI/GetDatas1?Prodgrp_id=" + Product_id;
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }

    GetBOMStock(product_id: number,Product_nm: string){
      let data: any = {};
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Product_id = product_id;
      data.Product_nm = Product_nm;
      // data.Dept_id = Dept_id;

      let url = this.hardfunc.BaseAPIUrl + "api/Manufacturing/bomsAPI/GetDatas1?Prodgrp_id=" + product_id;
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }

    DownloadPDF(product_id: number,Product_nm: string){
      let data: any = {};
      let headers = this.hardfunc.PostHeaders();
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Product_id = product_id;
      data.Product_nm = Product_nm;
      // data.Dept_id = Dept_id;

      let url = this.hardfunc.BaseAPIUrl + "api/Manufacturing/bomsAPI/DownloadPDF?Prodgrp_id=" + product_id;
      return this.http.post(url, data, { headers: headers,  responseType: 'blob' as 'json'  });
    }

    WithoutBOMPDF(product_id: number,Product_nm: string){
      let data: any = {};
      let headers = this.hardfunc.PostHeaders();
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Product_id = product_id;
      data.Product_nm = Product_nm;
      // data.Dept_id = Dept_id;

      let url = this.hardfunc.BaseAPIUrl + "api/Manufacturing/bomsAPI/WithoutBOMPDF?Prodgrp_id=" + product_id;
      return this.http.post(url, data, { headers: headers,  responseType: 'blob' as 'json'  });
    }
    
    GetBomStock(Product_id: number,Product_nm: String, Dept_id:number){
      let data: any = {};
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Product_id = Product_id;
      data.Product_nm = Product_nm;
      data.Dept_id = Dept_id;

      let url = this.hardfunc.BaseAPIUrl + "api/Manufacturing/bomsAPI/GetBomStock";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }

    GetBomItems(Product_id: number){
      let data: any = {};
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Product_id = Product_id;

      let url = this.hardfunc.BaseAPIUrl + "api/Manufacturing/bomsAPI/GetDatabyId";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }

    getDatasGrn(data:any) {
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      let url = this.hardfunc.BaseAPIUrl+"api/Store/grnsAPI/GetDatas"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
    }
    getDatasGrn1(data:any) {
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      let url = this.hardfunc.BaseAPIUrl+"api/Store/grn1sAPI/GetDatas"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
    }
    SelectedGRN(data: any) {
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      let url = this.hardfunc.BaseAPIUrl+"api/Store/grnsAPI/ImportGRN"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
    }
    Ignore_Items(Itcode:number,str1:string){
      let data: any = {};
      data.itcode = Itcode;
      data.str1 = str1;
      let url = this.hardfunc.BaseAPIUrl+"api/ProductsAPI/ChangeStatus?itcode="+Itcode+"&En="+str1;
      return this.http.get(url, { headers: this.getheaders});
    }
    ReceivedGRN(data: any){    
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      let url = this.hardfunc.BaseAPIUrl+"api/Store/grnsAPI/GetDatas"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
    }
    StockRep(data: any) {
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Fdate = new Date(data.Fdate);
      data.Tdate = new Date(data.Tdate);
      console.log(data);
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/StockRep";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    AllDeptPdf(data: any){
      data.Firm = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/AllDeptPdf"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders(),  responseType: 'blob' as 'json'});
    }
    StkBelowMinLevel(data: any){      
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Fdate = new Date(data.Fdate);
      data.Tdate = new Date(data.Tdate);
      
      let url = this.hardfunc.BaseAPIUrl + "api/indentsAPI/StkBelowMinLevel";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    StockRepAll(data: any) {
      var headers = new HttpHeaders({
        'content-Type': 'application/json',
        'process-Data': 'false',
        'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
      data.Firm = this.hardfunc.Firmx;
      data.Yrx = '2021';
      data.Tdate = data.AsOnDate;
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/StockRepAll";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    StockRepAll1(grp_id: number,filterdata:any[],Division: string) {
      var headers = new HttpHeaders({
        'content-Type': 'application/json',
        'process-Data': 'false',
        'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
      let data: any = {};
      data.Prodgrp_id = grp_id;
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Tdate = data.AsOnDate;
      data.SubData = filterdata;
      data.Division = Division;
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/StockRepAll";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    ProductbySpara_id(grp_id: number) {
      var headers = new HttpHeaders({
        'content-Type': 'application/json',
        'process-Data': 'false',
        'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
      let data: any = {};
      data.Spara_id = grp_id;
      data.Firm = this.hardfunc.Firmx;
      // data.Yrx = '2021';
      // data.Tdate = data.AsOnDate;
      // data.SubData = filterdata;
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/ProductbySpara_id";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }

    UpdateStock(data: any){
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      console.log(data.Yrx);
      let url = this.hardfunc.BaseAPIUrl + "api/Sales/factransAPI/OpStockUPD";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    // gatepassImp(){
    //   var headers = new HttpHeaders({
    //     'content-Type': 'application/json',
    //     'process-Data': 'false',
    //     'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    //     })
    //   let url = this.hardfunc.BaseAPIUrl+"api/JwReportAPI/JwReport"
    //   return this.http.post(url, null, { headers: this.hardfunc.PostHeaders()});
    // }
    GatepassReceived(data: any){
      let url = this.hardfunc.BaseAPIUrl+"api/JwReportAPI/JwReport"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
    }
    SaveGatePass(data: any){
      let url = this.hardfunc.BaseAPIUrl+"api/JwReportAPI/ImportGatepass"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
    }
    IgnoreGatepass(Product_id: number, str1: string){
      let data: any = {};
      data.itcode = Product_id;
      data.str1 = str1;
      let url = this.hardfunc.BaseAPIUrl+"api/JwReportAPI/ChangeStatus?Product_id="+Product_id+"&En="+str1;
      return this.http.get(url, { headers: this.getheaders});
    }
    getDatas(Fdate: string,Tdate:string, Product_id: number,  Dept_id:number){
      let data: any = {};
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Fdate = Fdate;
      data.Tdate = Tdate;
      data.Product_id = Product_id;
      data.Dept_id = Dept_id;
      let url = this.hardfunc.BaseAPIUrl+"api/Sales/factransAPI/GetDatas?id="+Product_id;
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
    }
    GeneratePDF(Fdate: string,Tdate:string, Product_id: number,  Dept_id:number){
      let data: any = {};
      data.Fdate = Fdate;
      data.Tdate = Tdate;
      data.Firm = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Product_id = Product_id;
      data.Dept_id = Dept_id;
      let url = this.hardfunc.BaseAPIUrl+"api/Sales/factransAPI/GeneratePDF?id="+Product_id;
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders(),  responseType: 'blob' as 'json'});
    }

    GetInvendashoardData(data: any){
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Fdate = new Date(data.Fdate);
      data.Tdate = new Date(data.Fdate);
      let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/InventoryDashboard"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
    }
    //Jb Out Table
    JwOutDashboard(data: any){
      data.Firmx = this.hardfunc.Firmx;      
      let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/JwOutDashboard"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
    }
    //Jw In Table
    JwInDashboard(data: any){      
      data.Firmx = this.hardfunc.Firmx;      
      let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/JwInDashboard"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders()});
    }
    PdfDownloadInvdDash(data: any){
      
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Fdate = new Date(data.Fdate);
      data.Tdate = new Date(data.Fdate);
      data.Dept_id = data.Dept_id;
      let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/PDFDownload"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders(),  responseType: 'blob' as 'json'});
    }
    StkReportPDF(data: any,Transfer: string){
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Tdate = new Date(data.Tdate);
      data.OnlyTrans = Transfer;
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/StockReportPDF"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders(),  responseType: 'blob' as 'json'});
    }
    PDFJwOutdown(data: any){      
      data.Firmx = this.hardfunc.Firmx;
      data.Tdate = data.Fdate;
      let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/JwOutPDF"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders(),  responseType: 'blob' as 'json'});
    }
    JwIndownPDF(data: any){      
      data.Firmx = this.hardfunc.Firmx;
      data.Tdate = data.Fdate;
      let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/JwInPDF"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders(),  responseType: 'blob' as 'json'});
    }
    StockUpdateData(Store_id: number){
      let data: any = {};
      data.Dept_id = Store_id;
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/StockUpdate"
      return this.http.post(url, data, {headers: this.hardfunc.PostHeaders()});
    }

    GetBomStockForStkrepAll(Product_id: number){
      let data: any = {};
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Product_id = Product_id;
      //data.Product_nm = Product_nm;
      data.Dept_id = 1;

      let url = this.hardfunc.BaseAPIUrl + "api/Manufacturing/bomsAPI/GetBomStock";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    BomUtiby(id: number){
      let Firm = this.hardfunc.Firmx; //ProdGrp_id="+Grp_id + "&PrioLength=" + PrioLength
      let Yrx = this.hardfunc.Yrx;
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/BomUtilise?Firm=" + Firm + "&Yrx=" + Yrx + "&Product_id="+ id;
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders()});
    }
    EditStock(Facmast_id: number){
      let url  = this.hardfunc.BaseAPIUrl + "api/Sales/factransAPI/EditStock?id=" + Facmast_id;
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders()});
    }
    ProductDetails(Grp_id: number){
      let data: any = {
        Grp_id:Grp_id
      };
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/ProductDetail";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }

    ExistingItems(Grp_id: number,SysCode:string){
      let data: any = {
        Grp_id:Grp_id,
        SystemCode :SysCode
      };
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/SearchProducts";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    ProductSearch(Grp_id: number,SysCode:string){
      let data: any = {
        Grp_id:Grp_id,
        SysCode :SysCode
      };
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/ProductDetail";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    ProductItems(Grp_id: number){
      let data: any = {};
      data.Grp_id = Grp_id;
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/ProductDetail1";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    AllPrdDetails(){
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/ProductDetailAll";
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    FixStock(product_id: number, Min_stock: number, Prod_code: string){
      let data: any = {};
      data.product_id = product_id;
      data.Min_stock = Min_stock;
      data.Prod_code = Prod_code;
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/FixStock";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    CategoryUPD(product_id: number, Category: string){
      let data: any = {};
      data.product_id = product_id;
      data.Category = Category;
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/CategoryUpdate";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    // For Prd Group --Update Product Code
    FixStock1(Product_id: number,Prod_code1: string,Prod_code2,Prod_code3){
      let data: any = {};
      data.Product_id = Product_id;
      data.Prod_code1 = Prod_code1;
      data.Prod_code2 = Prod_code2;
      data.Prod_code3 = Prod_code3;
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/FixStock1";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    GatepassDetail(TranType: string, Ref_id: number){
      let data: any = {};
      data.Firm = this.hardfunc.Firmx;
      data.TranType = TranType;
      data.Ref_id = Ref_id;
      let url = this.hardfunc.BaseAPIUrl + "api/GatepassDetail/ProductDetail";
      return this.http.post(url, data, {headers: this.hardfunc.PostHeaders()})
    }
    parabygroup_id(grp_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/PrdGroupsAPI/ParabyGroup_id?Group_id=" + grp_id;
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    // Get Para by Group Id 1
    parabygroup_id1(grp_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/PrdGroupsAPI/ParabyGroup_id1?Group_id=" + grp_id;
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    Jw_Summary(data: any){
      let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/Jobwork_Summary"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    JwDetailPDF(data: any){
      let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/JwDetailPDF"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders(),  responseType: 'blob' as 'json'});
    }
    Pparabygroup_id(grp_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/PrdGroupsAPI/PparabyGroup_id?Group_id=" + grp_id;
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }    
    GetPparaData(){
      let url = this.hardfunc.BaseAPIUrl + "api/manufacturing/pparasAPI/GetDatas";
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    GetParabyId(Ppara_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/manufacturing/pparasAPI/GetDatabyId?id="+Ppara_id
      return this.http.post(url, null, {headers: this.hardfunc.PostHeaders() });
    }
    GetProductbyId(Grp_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/PrdGroupsAPI/GetDatabyId?id="+Grp_id
      return this.http.post(url, null, {headers: this.hardfunc.PostHeaders() });
    }
    SavePrdgroup(data: any){                
      let url = this.hardfunc.BaseAPIUrl + "api/PrdGroupsAPI/SavePrdgroup"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    SavePara(data: any){          
      console.log(data);
      let url = this.hardfunc.BaseAPIUrl + "api/manufacturing/pparasAPI/SavePpara"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    deletePrdgrp(grp_id){
      let url = this.hardfunc.BaseAPIUrl + "api/PrdGroupsAPI/DeleteData?id=" + grp_id
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    GetSpara(Ppara_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetSparabyPpara_id?Ppara_id= "+Ppara_id
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    //Spara Code Update
    SparaCode(Prod_cd: string,Spara_id:number){
      let data: any = {};
      data.Spara_id = Spara_id;
      data.Prod_code2 = Prod_cd;
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/SparaCodeUpdate";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    GetMatrixPivot(Grp_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/PmatrixPivot?Prodgrp_id=" + Grp_id
      return this.http.post(url, null, {headers: this.hardfunc.PostHeaders() });
    }
    SavePpara(data: any){
      let url = this.hardfunc.BaseAPIUrl + "api/manufacturing/pparasAPI/SaveData"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    InventoryReports(data: any){      
      data.Firm = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/InvReports"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    InvReportsPDF(data: any){      
      data.Firm = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      let url = this.hardfunc.BaseAPIUrl + "api/InventoryDashboard/InvReportsPDF"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders(),  responseType: 'blob' as 'json'});
    }
    ProductSales(Grp_id: number,PrioLength: number){
      let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/ProductSales1?ProdGrp_id="+Grp_id + "&PrioLength=" + PrioLength
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    GetPparabyId(Grp_id: number){
      console.log(Grp_id);
      let data: any ={
        Grp_id: Grp_id
      }
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/ProductImport"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
      //ProductImport
    }
    GetSparabyId(Grp_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/Sales/tproductsAPI/GetPparabyGrp_id?id="+Grp_id
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    CheckPrdRange(fProd_id: number, tprod_id: number){
      let data: any ={
        fProd_id: fProd_id,
        tProd_id: tprod_id
      }
      let url = this.hardfunc.BaseAPIUrl + "api/PrdGroupsAPI/ChkProdRange"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    UpdateUserCode(Spara_id: number, UserCode: string){
      let data: any = {
        Spara_id: Spara_id,
        UserCode: UserCode,
      }
      let url = this.hardfunc.BaseAPIUrl + "api/Sales/sparasAPI/SaveUserCode"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    UpdSparaName(Spara_id: number, Spara_nm: string){
      let data: any = {
        Spara_id: Spara_id,
        Spara_nm: Spara_nm,
      }
      let url = this.hardfunc.BaseAPIUrl + "api/Sales/sparasAPI/SaveUserCode"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    AddToIndent(data: any){    
      console.log(data);
      let url = this.hardfunc.BaseAPIUrl + "api/indentsAPI/SaveIndent"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    NewIndent(data: any){    
      console.log(data);
      let url = this.hardfunc.BaseAPIUrl + "api/indentsAPI/NewIndent"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    PendIndEmail(data: any){      
      data.Firmx = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Fdate = new Date(data.Fdate);
      data.Tdate = new Date(data.Tdate);
      let url = this.hardfunc.BaseAPIUrl + "api/indentsAPI/PendIndentEmail"
      return this.http.post(url, data, {headers: this.hardfunc.PostHeaders() });
    }       
    UpdDivision(Product_id: number, Division: string){
      let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/UpdDivision?id=" + Product_id + "&Division=" + Division
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    GetCondbyId(Paracond_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/Sales/paracondsAPI/GetDatabyId?id=" + Paracond_id
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    SparaListbyPpara_id(Grp_id: number, Spara_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/Sales/paracondsAPI/SparaListbyPpara?Grp_id=" + Grp_id + "&Spara_id=" + Spara_id
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    GetSparabyPpara_id(SparaList: string, Ppara_id: number){
      let url = this.hardfunc.BaseAPIUrl + "api/Sales/paracondsAPI/SparabyPpara_id?SparaList=" + SparaList + "&Ppara_id=" + Ppara_id
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
    UpdateSrno(Product_id: number, Fprod_id: number, Srno: number){
      let url = this.hardfunc.BaseAPIUrl + "api/Manufacturing/bomsAPI/UpdateSrno?Product_id=" + Product_id + 
      "&Fprod_id=" + Fprod_id + "&Srno=" + Srno
      return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
    }
}
