import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { PurchaseService } from '../purchase.service';
import { AddItemsGrnComponent } from '../add-items-grn/add-items-grn.component';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { privateDecrypt } from 'crypto';
import { MatSnackBar } from '@angular/material';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { ɵangular_packages_platform_browser_platform_browser_l } from '@angular/platform-browser';
import { isNullOrUndefined } from 'util';

export interface DialogData {
  Pmst_cod: string,
  itcode: number,
  itno: number,
  year: string,
  pono: number,
  short_nm: string,
  RATE: number,
  Runit2: string,
  Qnty: number,
  pqnty: number,
  rqnty: number,
  Delivered: string,
  Extra_Deli: string,
  Dunit: string,
  Last_grn: string,
  Detail: string
}

@Component({
  selector: 'app-imp-grn',
  templateUrl: './imp-grn.component.html',
  styleUrls: ['./imp-grn.component.css']
})
export class ImpGrnComponent implements OnInit {

  loading: boolean = false;
  DataForm: FormGroup;
  Pmst_cod: string;
  itcode: number;
  itno: number;
  year: string;
  pono: number;
  short_nm: string;
  RATE: number;
  Runit2: string;
  Qnty: number;
  pqnty: number;
  rqnty: number;
  Delivered: string;
  Extra_Deli: string;
  Dunit: string;
  Last_grn: string;
  Detail: string;
  passedValues: object;
  pmstCod1: number = 0;
  ItemList: any = [];
  TempData: any;
  formarr: FormArray;
  initialValues: any[];

  constructor(private fb: FormBuilder,
    private PurchaseService: PurchaseService,
    private dialog: MatDialog,
    private commonservice: CommonService,
    private hardfunc: HardFuncService,
    private MatSnackBar: MatSnackBar,
    private NotificationService: NotificationService) { }

  ngOnInit() {
    this.DataForm = this.CreateFormGroup();
    this.formarr = new FormArray([]);
    
    this.initialValues = this.DataForm.value;
  }

  // AddItems() {
  //   (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubDatas());    
  // }
  GetPartyDetail() {
    console.log(this.DataForm.get("Grnno").value);
    if (this.DataForm.get("Grnno").value === "" || this.DataForm.get("Grnno").value === null) {
      this.commonservice.GetPartyDetail(this.DataForm.get("Pmst_cod").value, this.hardfunc.Firmx)
        .subscribe(
          (data: any) => {
            data.map(d => {
              console.log(d);
              this.ResetData();
              this.DataForm.get("Pmst_cod").patchValue(d.PMST_COD);
              this.DataForm.get("Party_name").patchValue(d.PARTY_NAME);
              this.DataForm.get("Party_city").patchValue(d.PARTY_CITY);
              this.DataForm.get("Gst_no").patchValue(d.GSTNO);
            });
          },
          err => {

          }
        )
    }
  }
  ItemsDialog() {   
    const dialogRef = this.dialog.open(GrnItems, {
      width: '1300px',
      height: '700px',

      data: {
        id: this.DataForm.get('Pmst_cod').value,
        PMST_COD: this.Pmst_cod,
        itcode: this.itcode,
        itno: this.itno,
        year: this.year,
        pono: this.pono,
        short_nm: this.short_nm,
        RATE: this.RATE,
        Runit2: this.Runit2,
        Qnty: this.Qnty,
        pqnty: this.pqnty,
        rqnty: this.rqnty,
        Delivered: this.Delivered,
        Extra_Deli: this.Extra_Deli,
        Dunit: this.Dunit,
        Last_grn: this.Last_grn,
        Detail: this.Detail
      }

      // data: {name: this.name, role: this.role}
    });
    dialogRef.afterClosed().subscribe(result => {

      // let index: number = 0;      

      // let formarray = (this.DataForm.controls['SubDatas'] as FormArray);   
      // let formgroup = (formarray.at(index) as FormGroup);                  
      //       let code = formgroup.controls["itcode"].value;
      //       console.log(code);            
      //       index++;    

        console.log(result);
        result.map(m => {
          // if (code != m.itcode) {
            this.formarr.push(
              this.fb.group({
                Pmst_cod: m.PMST_COD,
                year: m.year,
                pono: m.pono,
                Srno: m.itno,
                itcode: m.itcode,
                short_nm: m.short_nm,
                RATE: m.RATE,
                Runit2: m.runit,
                DQnty: m.dQnty,
                Qnty: m.Qnty,
                Rqnty: m.rqnty,
                Pqnty: m.pqnty,
                Delivered: "",
                Extra_Deli: "",
                Dunit: m.Unit,
                Last_grn: m.Last_grn,
                Detail: m.Detail,                
              })              
            );
            this.pmstCod1 = m.itcode;
          // }          
        });
        this.DataForm.setControl("SubDatas", this.formarr);
        console.log(this.DataForm.value);
        // let index = 0;
        // this.formarr.value.map(d => {  
        //   console.log(index);
        //   // console.log(m.itcode);
        //   // console.log(d.itcode);
        //   if(d.itcode === this.pmstCod1){            
        //     console.log('already added this items');
        //     (this.DataForm.controls['SubDatas'] as FormArray).removeAt(index);            
        //   }
        //   index++;
        // })
      // }
      // this.ItemListData(result);
      //let index: number = 0;      

      // result.map(m => {           
      // let formarray = (this.DataForm.controls['SubDatas'] as FormArray);   
      // // let formgroup = (formarray.at(index) as FormGroup);                                                                 
      // //       console.log(formgroup);
      // //       console.log(index);
      // //       console.log(m);
      // //       formgroup.controls["PMST_COD"].patchValue(m.PMST_COD);
      // //       formgroup.controls["year"].patchValue(m.year);
      // //       formgroup.controls["pono"].patchValue(m.pono);
      // //       formgroup.controls["itno"].patchValue(m.itno);
      // //       formgroup.controls["short_nm"].patchValue(m.short_nm);            
      // //       index++;          
      // let formgroup = (formarray.at(index) as FormGroup);                                                                 
      //       console.log(formgroup);
      //       console.log(index);
      //       console.log(m);
      //       formgroup.controls["PMST_COD"].patchValue(m.PMST_COD);
      //       formgroup.controls["year"].patchValue(m.year);
      //       formgroup.controls["pono"].patchValue(m.pono);
      //       formgroup.controls["itno"].patchValue(m.itno);
      //       formgroup.controls["short_nm"].patchValue(m.short_nm);            
      //       index++;          

      // }); 


      // // const formarr = new FormArray([]);
      // // console.log(formarr);
      // // result.map(m => {
      // //   formarr.push(
      // //     this.fb.group({
      // //       Pmst_cod: m.PMST_COD,
      // //       year: m.year,
      // //       pono: m.pono,
      // //       Srno: m.itno,
      // //       itcode: m.itcode,
      // //       short_nm: m.short_nm,
      // //       RATE: m.RATE,
      // //       Runit2: m.runit,
      // //       DQnty: m.dQnty,
      // //       Qnty: m.Qnty,
      // //       Rqnty: m.rqnty,
      // //       Pqnty: m.pqnty,
      // //       Delivered: "",
      // //       Extra_Deli: "",
      // //       Dunit: m.Unit,
      // //       Last_grn: m.Last_grn,
      // //       Detail: m.Detail,                        
      // //     })
      // //   );        
      // //   this.pmstCod1 = m.PMST_COD;        
      // });   
      console.log('The dialog was closed');
    });
  }

  grnRecord() {
    if (this.DataForm.get("Grnno").value === "") {
      this.ResetData();
    }
    this.PurchaseService.ImpGrnRecord(this.DataForm.get("Grnno").value)
      .subscribe((data: any) => {
        console.log(data);
        data.map(m => {
          this.DataForm.get("Pmst_cod").patchValue(m.Pmst_cod);
          this.DataForm.get("Party_name").patchValue(m.Party_name);
          this.DataForm.get("Party_city").patchValue(m.Party_city);
          this.DataForm.get("Chdt").patchValue(this.formatDate(m.ChDts));
          this.DataForm.get("Chno").patchValue(m.Chno);
          this.DataForm.get("Rcptdt").patchValue(this.formatDate(m.RcptDts));
          this.DataForm.get("Gate").patchValue(m.Gate);
          this.DataForm.get("Gsrno").patchValue(m.Gsrno);
        })
      })
  }
  GetGrnNo() {
    if (this.DataForm.get("Grnno").value === "") {
      this.ResetData();
    }
    this.PurchaseService.ImpGrnList(this.DataForm.get("Grnno").value)
      .subscribe((data: any) => {
        console.log(data);
        data.map(m => {
          this.formarr.push(
            this.fb.group({
              Pmst_cod: m.PMST_COD,
              year: m.year,
              pono: m.pono,
              Srno: m.srno,
              itcode: m.itcode,
              short_nm: m.short_nm,
              RATE: m.rate,
              Runit2: m.runit,
              DQnty: m.dqnty,
              Qnty: m.Qnty,
              Rqnty: m.qnty,
              Pqnty: m.pqnty,
              Delivered: m.OLD_DEL,
              Extra_Deli: m.edqnty,
              Dunit: m.dunit,
              Last_grn: m.last_grn,
              Detail: m.Detail,
            })
          );
        });
        this.DataForm.setControl("SubDatas", this.formarr);
      })
  }
  // ItemListData(data: any){      
  //     data.map(m => {
  //       this.formarr.push(
  //         this.fb.group({
  //           Pmst_cod: m.PMST_COD,
  //           year: m.year,
  //           pono: m.pono,
  //           Srno: m.itno,
  //           itcode: m.itcode,
  //           short_nm: m.short_nm,
  //           RATE: m.RATE,
  //           Runit2: m.runit,
  //           DQnty: m.dQnty,
  //           Qnty: m.Qnty,
  //           Rqnty: m.rqnty,
  //           Pqnty: m.pqnty,
  //           Delivered: "",
  //           Extra_Deli: "",
  //           Dunit: m.Unit,
  //           Last_grn: m.Last_grn,
  //           Detail: m.Detail,                        
  //         })
  //       );                   
  //     });
  //     this.DataForm.setControl("SubDatas", this.formarr);            
  // }
  SaveData() {
    console.log(this.DataForm.value);
    // this.PurchaseService.SaveImpGrn(this.DataForm.value)
    //   .subscribe((data: any) => {
    //     console.log(data);
    //     this.NotificationService.success("Dave Saved Sucessfully... ");
    //   },
    //   err => {
    //     this.loading = false;
    //     if (err.error.error_description) {
    //       this.openSnackBar(err.error.error_description, "dismiss");
    //     }
    //     else {
    //       this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
    //     }
    //   }
    // )
  }
  ResetData() {
    // if(this.DataForm.get("Pmst_cod").value){
    this.DataForm.reset();
    this.DataForm.patchValue(this.initialValues);
    let index: number = -1;
    let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
    formarray.value.map(d => {
      let formgroup = (formarray.at(index) as FormGroup);
      (this.DataForm.controls['SubDatas'] as FormArray).removeAt(index);
      index++;
    });
  }
  DeleteRow(i: number) {
    (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);
  }
  CreateFormGroup() {
    return this.fb.group({
      Pk_id: new FormControl(-1),
      Grnno: new FormControl(),
      Pono: new FormControl(),
      Podt: new FormControl(new Date()),
      Invdt: new FormControl(new Date()),
      Chno: new FormControl(''),
      Chdt: new FormControl(new Date()),
      Rcptdt: new FormControl(new Date()),
      Firm: new FormControl(''),
      Pmst_cod: new FormControl(''),
      Remark: new FormControl(''),
      Year: new FormControl(this.hardfunc.Yrx),
      Grn_yr: new FormControl(''),
      Party_name: new FormControl(''),
      Party_city: new FormControl(''),
      Gst_no: new FormControl(),
      Gate: new FormControl(''),
      Gsrno: new FormControl(),
      SubDatas: this.fb.array([this.CreateSubDatas()]),
    })
  }
  CreateSubDatas(): FormGroup {
    return this.fb.group({
      Pk_id: new FormControl(-1),
      Pmst_cod: new FormControl(''),
      itcode: new FormControl(),
      Srno: new FormControl(),
      year: new FormControl(''),
      pono: new FormControl(),
      short_nm: new FormControl(''),
      RATE: new FormControl(),
      Runit2: new FormControl(''),
      Qnty: new FormControl(),
      Pqnty: new FormControl(),
      Rqnty: new FormControl(),
      Delivered: new FormControl(),
      Extra_Deli: new FormControl(),
      Dunit: new FormControl(''),
      Last_grn: new FormControl(),
      Detail: new FormControl(''),
    });
  }
  openSnackBar(message: string, action: string) {
    this.MatSnackBar.open(message, action, {
      duration: 2000,
    });
  }
  private formatDate(date) {
    console.log(date);
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
}

/////////////////// List //////////////////

@Component({
  selector: 'grn-items',
  templateUrl: 'Grn-items.component.html',
})
export class GrnItems {

  PoItems: any = [];
  msgFromChild1 = [];
  TempData: any = [];
  Temp2: any = [];

  constructor(
    private dialogRef: MatDialogRef<ImpGrnComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private ReceivedData: any,
    private PurchaseService: PurchaseService
  ) { }

  ngOnInit() {
    this.AddToGrn();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  AddToGrn() {
    this.PurchaseService.PartyPoItems(this.ReceivedData.id)
      .subscribe((data: any) => {
        this.PoItems = data;
        this.PoItems.map(d => {
          d.Chk = false;
        });
      });
  }
  selectItems(data: any) {
    // if(data.Chk === true){
    this.TempData.push(data);
    // }    
  }

  // OK Click
  msgToParent() {
    this.ReceivedData = this.TempData;
    this.dialogRef.close(this.TempData);
  }
}