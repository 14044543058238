import { Component, OnInit } from '@angular/core';
import { PurchaseService } from '../../purchase.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ImpoItemsComponent } from '../impo-items/impo-items.component';
import { EditImpProComponent } from '../edit-imp-pro/edit-imp-pro.component';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';

@Component({
  selector: 'app-imp-prd-list',
  templateUrl: './imp-prd-list.component.html',
  styleUrls: ['./imp-prd-list.component.css']
})
export class ImpPrdListComponent implements OnInit {

  loading: boolean = false;
  PrdList: any = [];
  constructor(private PurchaseService: PurchaseService,
              private dialog: MatDialog,
              private adminservice: AdminService,
              private notificationservice: NotificationService,
              private hardfunc: HardFuncService
  ) { }

  ngOnInit() {
    this.GetData();
  }
  GetData(){
    this.PurchaseService.ImpPrdList()
      .subscribe((data: any) => {        
        this.PrdList = data;                
      },
      err => {
      this.loading = false;
      let errstr =  this.hardfunc.getError(err);
      console.log(err);
      this.notificationservice.warn(errstr);
      }
    )
  }
  ItemEdit(data: any){   
    console.log(data);
      const dialogRef = this.dialog.open(EditImpProComponent, {
        width: '1000px',
        height: '580px',
        data: { data: data}            
      });    
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');     
      });
    }
  DeleteItem(Product_id: number){
    this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
		.afterClosed().subscribe(res =>{
		  if(res){
			this.loading = true;
			const dialogRef = this.adminservice.DelImpItem(Product_id)
			.subscribe(
			  (data:any)=>{
				this.loading = false;
				this.notificationservice.warn("Data deleted successfully");				
        window.location.reload();
			  },
			  err => {
				this.loading = false;
				let errstr =  this.hardfunc.getError(err);
				console.log(err);
				this.notificationservice.warn(errstr);
			  }
			)
			
			// this.notificationService('Deleted Successfully');
		  }
		});
  }
   
}
