import { Component, OnInit, ViewChild } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { ManufacturingService } from '../../manufacturing.services';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-bom-sequence',
  templateUrl: './bom-sequence.component.html',
  styleUrls: ['./bom-sequence.component.css']
})
export class BomSequenceComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  formData: any;
  datasource: any = [];  
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private hardfunc: HardFuncService,
              private ManufacService: ManufacturingService,
              private notificationService: NotificationService
  ) { }

  ngOnInit() {
    // this.model.AsOnDate = new Date();
    this.GetData();
    this.loading = true;
  }
  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Product_id', 'Short_nm', 'Grp_nm', 'Loca1', 'Loca2', 'Loca3','Loca9', 'Loca4', 'Total'];
  }
  //1. api/ProductsAPI/StockRepAll
  GetData() {
    this.loading = true;
    this.datalist = [];
    let FromDate = this.hardfunc.hsConvDt(new Date());
    let ToDate = this.hardfunc.hsConvDt(new Date());

    this.formData = {
      Prodgrp_id: 0,
      Store_id: 1,
      Fdate: FromDate,
      Tdate: ToDate,     
      StkLevel: "A",
      Category: "All",
      OnlyTrans: "A",
      Division: "*All*",
    }
    this.ManufacService.StockRep(this.formData,)
      .subscribe(
        (data1: any) => {
          console.log(data1);
          this.datalist = data1;
          console.log(this.datalist);
          this.datalist.map(m => {
            m.OpStock = m.ClStock + m.Issue - m.Rcpt;
            m.Category = m.Category.trim();
            m.check = "";
            m.isClicked = false;
            m.MaxClicked = false;
            m.CatClicked = false;
          });
          this.dataSource = new MatTableDataSource(<any>data1);
          console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
          this.DisplayCol();
          this.loading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
}
