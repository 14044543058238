import { Routes } from '@angular/router';
import { GenIndComponent } from './gen-ind/gen-ind.component';
import { PoComponent } from './po/po.component';
import { GatInwComponent } from './gat-inw/gat-inw.component';
import {GrnComponent} from './grn/grn.component';
import { PurRetComponent } from './pur-ret/pur-ret.component';
import { PrchkListComponent } from './prchk-list/prchk-list.component';
import { IndentListComponent } from './indent-list/indent-list.component';
import { IndenterCrudComponent } from './indenter-crud/indenter-crud.component';
import { IndenterListComponent } from './indenter-list/indenter-list.component';
import { PoregComponent } from './poreg/poreg.component';
import { IndentComponent } from './indent/indent.component';
import { IndentRegComponent } from './indent-reg/indent-reg.component';
import { PoGenComponent } from './po-gen/po-gen.component';
import { JobworkOutComponent } from './jobwork-out/jobwork-out.component';
import { JobworkInwardComponent } from './jobwork-inward/jobwork-inward.component';
import { PoPartyListComponent } from './po-gen/po-party-list/po-party-list.component';
import { ImpoPartyComponent } from './po-gen/impo-party/impo-party.component';
import { ImpoItemsComponent } from './po-gen/impo-items/impo-items.component';
import { PoManComponent } from './po-man/po-man.component';
import { ImpGrnComponent } from './imp-grn/imp-grn.component';

 export const PurchaseRoutes: Routes = [
      {
        path: 'genInd',
        component: GenIndComponent,
        data: { title: 'General Indent' }
      },
      {
        path: 'po',
        component: PoComponent,
        data: { title: 'PO' }
      },
      {
        path: 'gatInw',
        component: GatInwComponent,
        data: { title: 'Gate Inward' }
      },
      {
        path: 'grn',
        component: GrnComponent,
        data: { title: 'GRN' }
      },
      {
        path: 'purRet',
        component: PurRetComponent,
        data: { title: 'Purchase Return' }
      },
      {
        path: 'PurCheckList',
        component: PrchkListComponent,
        data: { title: 'PurCheckList' }
      },
      {
        path: 'Indent',
        component: IndentComponent,
        data: { title: 'Indent' }
      },
      {
        path: 'Indenter-list',
        component: IndenterListComponent,
        data: { title: 'Indenter-list' }
      },
      {
        path: 'Poreg',
        component: PoregComponent,
        data: { title: 'Poreg' }
      },
      {
        path: 'indent-reg',
        component: IndentRegComponent,
        data: { title: 'indent-reg' }
      },
      {
        path: 'po-gen',
        component: PoManComponent,
        data: { title: 'po-gen' }
      },
      {
        path: 'Jobwork-out',
        component: JobworkOutComponent,
        data: { title: 'Jobwork-out' }
      },
      {
        path: 'Jobwork-inward',
        component: JobworkInwardComponent,
        data: { title: 'Jobwork-inward' }
      },      
      {
        path: 'Po-Party-list',
        component: PoPartyListComponent,
        data: { title: 'Po-Party-list' }
      },
      {
        path: 'ImPo-party',
        component: ImpoPartyComponent,
        data: { title: 'ImPo-party' }
      },
      {
        path: 'impo-items',
        component: ImpoItemsComponent,
        data: { title: 'impo-items' }
      },
      {
        path: 'imp-grn',
        component: ImpGrnComponent,
        data: { title: 'imp-grn' }
      },
      // {
      //     path: 'po-gen/:#gen_po',
      //     component: PoGenComponent,
      //     data:{Title:'po-gen'}
      // }, 
 ];
