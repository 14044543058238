import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormControlDirective } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import { PendordComponent } from '../pendord.component';

@Component({
  selector: 'app-so-add-fltr',
  templateUrl: './so-add-fltr.component.html',
  styleUrls: ['./so-add-fltr.component.css']
})
export class SoAddFltrComponent implements OnInit {

  repmodel: any = {};
  DataForm: FormGroup;
  msgFromChild1: any [];
  constructor(
  ) { }

  ngOnInit() {
    // this.DataForm = this.createFormGroup();
  }
  // GetData(){
  //   console.log(this.DataForm.get("OrdDate").value);
  //   this.DataForm.get("OrdDate").value;
    
  // }
  msgToParent(animal: string) {
    // console.log(OrdDate);
    let dt = this.DataForm.get("OrdDate").value;
    this.msgFromChild1.push(animal);
    console.log(this.msgFromChild1);    
  }
  
  // createFormGroup() {
  //   return this.fb.group({      
  //     OrdDate: new FormControl(null),      
  //     Prio: new FormControl(''),
  //   });
  // }
  // readonly dialogRef = Inject(MatDialogRef<PendordComponent>);
  // readonly data = Inject<DialogData>(MAT_DIALOG_DATA);
  // readonly animal = model(this.data.animal);  
}
