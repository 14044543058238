import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { ManufacturingService } from '../manufacturing.services';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-jobwork-repo',
  templateUrl: './jobwork-repo.component.html',
  styleUrls: ['./jobwork-repo.component.css']
})
export class JobworkRepoComponent implements OnInit {

  DataForm: FormGroup;
  JwSummData: any = [];
  PartyName: string;

  constructor(private fb: FormBuilder,
              private hardfunc: HardFuncService,
              private ManufacturingService: ManufacturingService,
              private MatSnackBar: MatSnackBar
  ) { }

  ngOnInit() {    
    this.DataForm = this.createFormGroup();
    this.GetData();
  }
  GetData(){          
    this.ManufacturingService.Jw_Summary(this.DataForm.value)
      .subscribe((data: any) => {
        this.JwSummData = data;
        let chlnNO = 0;
        this.JwSummData.map(d => {
          if(d.PARTY_NAME != this.PartyName){
            this.PartyName = d.PARTY_NAME.trim();
          }               
          if(d.outwardno == chlnNO)     
          {            
            d.outwardno = "";            
          }
          chlnNO = d.outwardno;          
        });        
        console.log(this.JwSummData);
      }, err => {
        console.log(err);
      });
  }
  DownloadPDF(){
    this.ManufacturingService.JwDetailPDF(this.DataForm.value)
      .subscribe((data: any) => {
        const data1 = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = data1;
        link.target = "_blank";
        link.download = "Jobwork-repo.pdf";
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      },
        err => {
          if (err.error.error_description) {
            this.opensnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.opensnackBar("Error in getting data, Please Check Server Connectivity...", "dismiss")
          }
        })
  }
  opensnackBar(message: string, action: string) {
    this.MatSnackBar.open(message, action, {
      duration: 2000,
    });
  }
  createFormGroup() {
      return this.fb.group({        
        Firmx: this.hardfunc.Firmx,
        Pmst_cod: new FormControl(''),
        Fdate: new FormControl(new Date()),
        Tdate: new FormControl(new Date()),        
        Product_id: new FormControl()
      });
    }
}
