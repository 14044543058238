import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
@Injectable({
  providedIn: 'root'
})
export class MasterService {
  
  getheaders;
  constructor(private http: HttpClient,
              private hardfunc: HardFuncService) { 
                this.getheaders = new HttpHeaders({
                  'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
                })
              }

  GetHandleData(){
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetDataAll"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetDatabyId(Handle_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetdatabyId?Handle_id=" + Handle_id
    return this.http.get(url, { headers: this.getheaders });
  }
  DeleteMktUser(Handle_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/DeleteHandle?id=" + Handle_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetDepts(){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/deptssAPI/GetDatas"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetDeptsbyId(Dept_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/deptssAPI/GetDatabyId?id="+ Dept_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  SaveDepts(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/deptssAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  SaveTgroup(data:FormData){        
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/tgroupsAPI/SaveData"
    var headers = new HttpHeaders({
      
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
		return this.http.post(url, data, { headers:headers });
  }
  GetTgroup(){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/tgroupsAPI/GetDatas"
    return this.http.post(url, null, { headers: this.getheaders });
  }
  GetTgroupbyId(id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/tgroupsAPI/GetDatabyId?id="+id;
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  SaveHoliday(Firmx: string,Occa: string,id: number){
    let data: any = {};
    data.Holiday_id = id;
    data.Firm = Firmx;
    data.Occa = Occa;
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/holidaysAPI/SaveData";
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  GetHolidayData(){
    let data: any = {};
    data.Firm = this.hardfunc.Firmx;
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/holidaysAPI/GetDatas"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  GetHolidayById(id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/holidaysAPI/GetDatabyId?id="+id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  DeleteHoliday(Holiday_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/holidaysAPI/DeleteData?id="+Holiday_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetDevMan(){
    let data: any = {};
    data.Firm = this.hardfunc.Firmx;
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/devmansAPI/GetDatas"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  SaveDevMan(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/devmansAPI/SaveData";
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  GetDevManById(id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/devmansAPI/GetDatabyId?id="+id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  DeleteDevMan(Device_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/devmansAPI/DeleteData?id="+Device_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  JoinEmpDash(Fdate: string,Tdate: string,Firmx: string){
    let data: any = {};
    data.Fdate = Fdate;
    data.Tdate = Tdate;
    data.Firmx = Firmx;
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/devmansAPI/hrDash_empJoin"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  ResignEmpDash(Fdate: string,Tdate: string,Firmx: string){
    let data: any = {};
    data.Fdate = Fdate;
    data.Tdate = Tdate;
    data.Firmx = Firmx;
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/devmansAPI/hrDash_empResign"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  SaveEmpDetail(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/empmastsAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  GetEmpList(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/empmastsAPI/GetDatas"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  GetEmpById(id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/empmastsAPI/GetDatabyId?id=" +id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  EmpDeletebyId(id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/empmastsAPI/DeleteData?id=" +id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetPrdgroup(){
    let url = this.hardfunc.BaseAPIUrl + "api/PrdGroupsAPI/GetPrdGroup"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  getTproductbyId(id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/tproductsAPI/GetDatabyId?id=" + id
    return this.http.post(url, null, {headers: this.hardfunc.PostHeaders()});
  }
  SavePparaValue(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/sparasAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  SaveNewPpara(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/manufacturing/pparasAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  getSparabyPpara(Ppara_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/sparasAPI/GetSparabyPpara_id?id="+ Ppara_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetSparabyPpara_id(Ppara_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/sparasAPI/GetDatabyId?id="+ Ppara_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  SaveSparaVal(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/PrdGroupsAPI/SaveNewPrd"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  GetSparabyId(Ppara_id: any){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/sparasAPI/GetSparabyPpara_id?id="+ Ppara_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  AddNewBOM(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/Manufacturing/bomsAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  NewSparaVal(data: any, dGrp_id: number, sGrp_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/sparasAPI/SaveNewSpara?sGrp_id="+sGrp_id +"&dGrp_id=" + dGrp_id
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  GetbombyId(Product_id: number){
    let data: any = {
      Firmx: this.hardfunc.Firmx,
      Yrx: this.hardfunc.Yrx,
      Product_id: Product_id,
    }
    let url = this.hardfunc.BaseAPIUrl + "api/Manufacturing/bomsAPI/GetDatabyId"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  DeleteItemById(PRODUCT_ID: number,tProduct_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/DeleteItemById?Product_id="+PRODUCT_ID + "&tProduct_id=" + tProduct_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  DeletePpara(Ppara_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/manufacturing/pparasAPI/DeleteData?id="+Ppara_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  SaveParacond(data: any, Grp_id,Para_id: number,Spara_id: number){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/paracondsAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  PparaDenyAllow(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/paracondsAPI/PparaDenyAllow"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  PparaStatus(Spara_id: string){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/paracondsAPI/PparaStatus?SparaList=" + Spara_id
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  RemoveParacond(data: any){
    console.log(data.Apply_to);
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/paracondsAPI/DeleteData?id="+data.Apply_to
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  GetProduct(Grp_id: number){
    let data: any = {};
    console.log(Grp_id);
    data.Prodgrp_id = Grp_id;
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetProductList"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
  ProductUpd(Product_id: number, Short_nm: string){
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/ProductUpd?Product_id="+Product_id + "&Short_nm=" + Short_nm
    return this.http.get(url, { headers: this.getheaders });
  }
  GetAllFirms(){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/firmmastsAPI/GetDatas"
    return this.http.post(url, null, { headers: this.hardfunc.PostHeaders() });
  }
  UpdFirm(data: any){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/firmmastsAPI/SaveData"
    return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
  }
}
