import { Injectable } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  constructor(private hardfunc:HardFuncService ,
              private http:HttpClient) { }
    
    TrialBalance(cond: string, Username: string, Tdate: string,Type: string) {
      var headers = new HttpHeaders({
        'content-Type': 'application/json',
        'process-Data': 'false',
        'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
      let data: any = {};
      data.Firm = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Cond = cond;
      data.Tdate = Tdate;
      data.Username = Username;
      data.Type = Type;
      let url = this.hardfunc.BaseAPIUrl + "api/PartybalAPI/TrialBal";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    GetTriBl(Username: string, Tdate: string,Type: string) {
      var headers = new HttpHeaders({
        'content-Type': 'application/json',
        'process-Data': 'false',
        'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
      let data: any = {};
      data.Firm = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      // data.Cond = cond;
      data.Tdate = Tdate;
      data.Username = Username;
      data.Type = Type;
      let url = this.hardfunc.BaseAPIUrl + "api/PartybalAPI/TrialBal";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    TrblCreditPDF(Username: string, Tdate: string,Type: string) {
      var headers = new HttpHeaders({
        'content-Type': 'application/json',
        'process-Data': 'false',
        'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
      let data: any = {};
      data.Firm = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      // data.Cond = cond;
      data.Tdate = Tdate;
      data.Username = Username;
      data.Type = Type;
      let url = this.hardfunc.BaseAPIUrl + "api/PartybalAPI/TriblPDF";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders(),  responseType: 'blob' as 'json'});
    }
    TrblDebiPDF(Username: string, Tdate: string,Type: string) {
      var headers = new HttpHeaders({
        'content-Type': 'application/json',
        'process-Data': 'false',
        'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
        })
      let data: any = {};
      data.Firm = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      // data.Cond = cond;
      data.Tdate = Tdate;
      data.Username = Username;
      data.Type = Type;
      let url = this.hardfunc.BaseAPIUrl + "api/PartybalAPI/TrblDebitPDF";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders(),  responseType: 'blob' as 'json'});
    }
    getData(Pmst_cod: string,data: any,Fdates:string,Tdates:string){
      data.Firm = this.hardfunc.Firmx;
      data.Yrx = this.hardfunc.Yrx;
      data.Pmst_cod = Pmst_cod;
      data.Fdates = Fdates;
      data.Tdates = Tdates;
      // data.Fdates = data.Fdate;
      // data.Tdates = data.Tdate;
      let url = this.hardfunc.BaseAPIUrl + "api/AcLedgerAPI/getData";
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    GetTribl(){
      let dt = moment(new Date()).format("DD/MM/yyyy");
      console.log(dt);

      let data: any = {
        Firmx: this.hardfunc.Firmx,
        Yrx: this.hardfunc.Yrx,
        // sqlDt: dt,
      }
      let url = this.hardfunc.BaseAPIUrl + "api/AcLedgerAPI/TrailBalance"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    ChqpayData(Tdate: string){      
      let data: any = {};
      // data.Vtype = Type;
      data.Firmx = this.hardfunc.Firmx;
      data.Tdate = Tdate;
      let url = this.hardfunc.BaseAPIUrl + "api/PartybalAPI/ChqpayData"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    WithoutDetail(Tdate: string){      
      let data: any = {};      
      data.Tdate = Tdate;
      let url = this.hardfunc.BaseAPIUrl + "api/PartybalAPI/WithoutDetail"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    ChqPayMail(data: any, Tdate: string){      
      let data1: any = {};
      // data1.Pmst_cod = PMST_COD;
      data1.Firmx = this.hardfunc.Firmx;
      data1.Tdate = Tdate;
      let url = this.hardfunc.BaseAPIUrl + "api/PartybalAPI/ChqpayMail"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    UpdEmail(Firm: string, Pmst_cod: string, Email: string){
      let data: any = {};
      data.Firm = Firm;
      data.Pmst_cod = Pmst_cod;
      data.Email = Email;      
      let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/UpdateEmail"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
    UpdPhone(Firm: string, Pmst_cod: string, Phone: string){
      let data: any = {};
      data.Firm = Firm;
      data.Pmst_cod = Pmst_cod;
      data.Phone = Phone;      
      let url = this.hardfunc.BaseAPIUrl + "api/CollectionAPI/UpdatePhone"
      return this.http.post(url, data, { headers: this.hardfunc.PostHeaders() });
    }
}
