import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/Common/common.service';
import { PurchaseService } from '../../purchase.service';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { PartyHelpComponent } from 'src/app/Views/shared/party-help/party-help.component';
import {Observable} from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-impo-party',
  templateUrl: './impo-party.component.html',
  styleUrls: ['./impo-party.component.css']
})
export class ImpoPartyComponent implements OnInit {

  repmodel: any = {};
  DirectorList: any = [];
  DataForm: FormGroup;
  initialValues : any [];
  loading: boolean = false;
  PartyList: any = [];
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];  
  filteredStreets: Observable<string[]>;
  filteredOptions: any = [];
  CountryData: any = [];

  constructor(private hardfunc: HardFuncService,
              private _http: HttpClient,
              private fb: FormBuilder,
              private commonservice: CommonService,
              private PurchaseService: PurchaseService,
              private dialog: MatDialog,
              private NotificationService: NotificationService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,              
            ) { }

  ngOnInit() {
    this.loading = true;    
    this.GetPartyList();
    this.GetHandles();
    this.GetCountry();
    this.DataForm = this.CreateFormGroup();
    this.initialValues = this.DataForm.value;
    this.DataForm.get('Party_name').valueChanges.subscribe(response => {
      this._filter(response);
    });    
    // this.GetDataById();
  }

  SaveData() {   
    this.PurchaseService.POPartySave(this.DataForm.value)
      .subscribe((data: any) => {
        console.log(data);
        this.NotificationService.success("Data Saved Successfully... ");
        this.ResetData();
        window.location.reload();
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        this.NotificationService.warn(errstr);
      });
  }
  GetParty(){
    this.PartyList.map(d => {
      if(d.nm === this.DataForm.get("Party_name").value){
        console.log(d);
        this.DataForm.patchValue(d);
      }
    });
  }
  GetPartyList() {
    this.loading = true;
    this.PartyList = [];
    let data: any = {};    
    data.Firmx = this.hardfunc.Firmx;
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/partysAPI/GetDatas";
    this._http.post(url, null, this.repmodel)
      .subscribe((data1: any) => {                
        this.loading = false;        
        this.PartyList = data1;            
        this.PartyList = this.PartyList.map((x) => {
          return {
            nm: x.Party_name.trim(), //+'-'+ x.Party_city.trim() + '-'+ x.Party_Stat.trim()
            id: x.Party_id, 
            Address: x.Address,
            Bus_email: x.Bus_email,
            Bus_phone: x.Bus_phone,
            Busregno: x.Busregno,
            Country_id: x.Country_id,
            Email1: x.Email1,
            Party_id: x.Party_id,
            Person1: x.Person1,
            Phone1: x.Phone1,
            Ulfmlisting: x.Ulfmlisting,
            NFF: x.NFF,
            ANW: x.ANW,
            NTL: x.NTL,
          }
        });          
      }, err => {
        console.log(err);
      });
  }
  PartyListbyFirm(Firm: string) {
    let data = {
      Firmx: Firm,      
      Cond: "a.Party_stat = ''FOREIGN''"
    }
    const dialogRef = this.dialog.open(PartyHelpComponent, {
      width: '1200px',
      height: '848px',
      data
    });
    dialogRef.afterClosed().subscribe(result => {           
      if(Firm === "NFF"){
        this.DataForm.get('NFF').patchValue(result.id);
      }      
      if(Firm === "ANW"){
        this.DataForm.get('ANW').patchValue(result.id);
      }      
      if(Firm === "NTL"){
        this.DataForm.get('NTL').patchValue(result.id);
      }          
    });
  }
  
  HelpDialog() {
    let data = {
      Firmx: this.hardfunc.Firmx,
      Cond: " a.Grp in  (''B'',''H'' ) "
    }
    const dialogRef = this.dialog.open(PartyHelpComponent, {
      width: '970px',
      height: '848px',
      data
    });
    dialogRef.afterClosed().subscribe(result => {      
      this.DataForm.get('Party_name').patchValue(result.id + '-' + result.nm.trim());
      this.DataForm.get('Party_addr').patchValue(result.Party_adr1.trim() + '-' + result.Party_adr2.trim() + '-' + result.Party_adr3.trim()
        + '-' + result.Party_city.trim() + '-' + result.Party_Stat.trim());
      this.DataForm.get('Party_city').patchValue(result.Party_city.trim());
      this.DataForm.get('Party_state').patchValue(result.Party_Stat.trim());
    });
  }
  GetPartyDetail() {
    this.commonservice.GetPartyDetail(this.DataForm.get("Pmst_cod").value, this.hardfunc.Firmx)
      .subscribe(
        (data: any) => {
          var data1 = data[0];          
          this.DataForm.get("PartyName").patchValue(data1.PARTY_NAME.trim());
          this.DataForm.get("Party_city").patchValue(data1.PARTY_CITY.trim());
          this.DataForm.get("Bus_email").patchValue(data1.EMAIL.trim());
        },
        err => {

        }
      )
  }
  GetHandles() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=D";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.DirectorList = data1;
      }, err => {
        console.log(err);
      });
  }
  GetCountry(){
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/impordsAPI/GetCountry"
    this._http.post(url, null, { headers: this.hardfunc.PostHeaders() })
      .subscribe((data: any) => {
        this.CountryData = data;        
      }, err => {
        console.log(err);
      });
  }
  // addItems() {
  //   (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubData());
  // }
  DeleteRow(i: number) {
    (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);
  }
  ResetData() {    
      this.DataForm.reset();
      this.DataForm.patchValue(this.initialValues);
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });    
  }
  CreateFormGroup() {
    return this.fb.group({
      Party_id: new FormControl(-1),
      Party_name: new FormControl('',Validators.required),
      Country_id: new FormControl(Validators.required),
      Address: new FormControl(''),            
      Bus_email: new FormControl(''),
      Bus_phone: new FormControl(''),
      Busregno: new FormControl(''),
      Person1: new FormControl(''),
      Email1: new FormControl(''),
      Phone1: new FormControl(''),
      NFF: new FormControl(''),
      ANW: new FormControl(''),
      NTL: new FormControl(''),
      Ulfmlisting: new FormControl('true'),      
    });
  }  

  // private _filter(value: string): string[] {
  //   const filterValue = this._normalizeValue(value);
  //   return this.streets.filter(street => this._normalizeValue(street).includes(filterValue));
  // }

  // private _normalizeValue(value: string): string {
  //   return value.toLowerCase().replace(/\s/g, '');
  // }
  
  private _filter(value: string) {
    if (!value)
    {
      this.filteredOptions = [];
      return;
    }
    if (value.length < 1) {
      this.filteredOptions = [];
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredOptions = this.PartyList.filter(option => option.nm.toLowerCase().includes(filterValue));
  }
}
