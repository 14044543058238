import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../admin.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { MatTableDataSource, MatPaginator, MatSnackBar } from '@angular/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-session-data',
  templateUrl: './session-data.component.html',
  styleUrls: ['./session-data.component.css']
})
export class SessionDataComponent implements OnInit {

  loading: boolean = false;
  datalist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  DataForm: FormGroup;
  Users: any = [];
  Modules: any = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private adminservice: AdminService,
              private hardfunc: HardFuncService,
              private NotificationService: NotificationService,
              private fb: FormBuilder,
              private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.loading = true;
    this.GetAllUsers();
    this.GetAllModules();
    this.DataForm = this.CreateFormGroup();
    this.GetSessionData();
  }
  DisplayCol(){
    this.displayedColumns = ['Session_id','Login_id','Username','wmodule_nm','Tdate','LogoutTime','Ip_address'];
  }
  GetSessionData(){
    this.loading = true;
    let Fdate = this.hardfunc.hsConvDt(this.DataForm.controls.Fdate.value);
    let Tdate = this.hardfunc.hsConvDt(this.DataForm.controls.Tdate.value);
    this.DataForm.controls.Fdate.patchValue(Fdate);
    this.DataForm.controls.Tdate.patchValue(Tdate);

    this.adminservice.getsessiondata(this.DataForm.value)
      .subscribe((data: any) => {
        this.datalist = data;
        // this.datalist.map(m => {
        //   console.log(m.Tdate);
        //   m.Tdate = moment(m.Tdate).format("DD/MM/yyyy");
        // })
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
        this.dataSource.paginator = this.paginator;
        this.loading = false;
      },
      err => {
        this.loading = false;
        let errstr =  this.hardfunc.getError(err);
        console.log(err);
        this.NotificationService.warn(errstr);        
      }
      )
  }
  
  GetAllUsers() {
    this.loading = true;
    this.adminservice.getAllUsers()
    .subscribe(
      (data:any) => {
        this.Users = data;
        this.loading = false;
      },
      err => {
        this.loading = false;
        console.log(err);
        this.openSnackBar("Error in getting data , please check server connectivity...  ","dismiss");
      });
  }
  disableField(checked) {
    if (!checked) {
      this.DataForm.controls["Username"].patchValue('*All*');
      this.DataForm.controls["Module_id"].patchValue(-1);
    } else {
      this.DataForm.value.Username;
    }
}
  GetAllModules() {
    this.loading = true;
    this.datalist = [];
    this.adminservice.getAllModules()
    .subscribe(
      (data:any) => {
        this.Modules = data;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.DisplayCol();
        this.loading = false;
      },
      err => {
        this.loading = false;
        let errstr =  this.hardfunc.getError(err);
        console.log(err);      
      }
    )
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  CreateFormGroup(){
    return this.fb.group({
      Fdate: new FormControl(new Date()),
      Tdate: new FormControl(new Date()),
      Username: new FormControl('*All*'),
      Module_id: new FormControl(-1),
      Opstock: new FormControl()
    });
  }
}
